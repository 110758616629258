<template>
    <div id="menu__poem" >
        <!--      <transition-group name="list" tag="div">-->
        <!--          <div   v-for="(category, i) in menu" v-bind:key="i">-->
        <!--              <p class="menu__poem-entry" @click="selectCategory(category.name)"-->
        <!--                 :style= "[category.active ? { 'color': `${category.color}` } : { 'color': menuColor }]"-->
        <!--              >-->
        <!--                  {{ category.name.toUpperCase() }}-->
        <!--              </p>-->
        <!--              <div class="menu__poem-category d-flex justify-content-center" :class="{menu__poem_visible: category.active}" >-->
        <!--                  <div v-for="(subCategory, j) in category.subCategories" v-bind:key="j">-->
        <!--                  <span @click="selectCategory(subCategory.name)"-->
        <!--                        :style= "[subCategory.active ? { 'color': `${subCategory.color}` } : { 'color': menuColor }]"-->
        <!--                  >-->
        <!--                      {{subCategory.name.toUpperCase()}}-->
        <!--                  </span>-->
        <!--                  </div>-->
        <!--              </div>-->
        <!--          </div>-->
        <!--      </transition-group>-->

<!--        <div v-if="$store.getters.menuPoem.length > 0">-->

        <transition name="fade" mode="out-in">

            <svg v-if="menuState" class="login__icon_clear_email" viewBox="187.719 22.889 14.47 14.469" @click="clearAll">
                <path class="login__icon_clear_email-path" d="M 188.8324584960938 37.35843276977539 L 187.718994140625 36.24566650390625 L 193.8410034179688 30.12371635437012 L 187.718994140625 24.00176620483398 L 188.8324584960938 22.88899993896484 L 194.9537658691406 29.01025390625 L 201.0757751464844 22.88899993896484 L 202.1892395019531 24.00176620483398 L 196.0672302246094 30.12371635437012 L 202.1892395019531 36.24566650390625 L 201.0757751464844 37.35843276977539 L 194.9537658691406 31.23648071289062 L 188.8324584960938 37.35843276977539 Z">
                </path>
            </svg>
        </transition>

        <transition name="fade" mode="out-in">
            <p class="mobileclose" @click="swipeDown">Bezár</p>
        </transition>
        <div class="poemflex">


            <p class="menu__poem-entry szellem" @click="selectCategory('Szellem')" :class="{ active: menu[0].active }">

<!--                <svg viewBox="0 0 110.29 3.85"><path d="M96.65,0l-4,0A5.21,5.21,0,0,1,88,1.56,9,9,0,0,1,83.6.05l-5.68,0a7.16,7.16,0,0,1-1.31.1c-2.91,0-3,1-6.62-.07l-9.33,0a8.81,8.81,0,0,1-4.53,1A9.6,9.6,0,0,1,52.25.17L15.54.31C11,1.53,10.76,1.88,9.41,1.56L6.76.93,0,2.16,11.53,3.85h.28A5.43,5.43,0,0,1,15.92,2.8a9.28,9.28,0,0,1,3.62,1.05H30.07a6.16,6.16,0,0,1,2.11,0h12a7.9,7.9,0,0,1,3.62-.59,7.18,7.18,0,0,1,2.95.59H89.06c3.86-1.06,4.16-1.36,5.43-1.05l4.42,1.05h3.44l7.94-1.92Z"/></svg>-->

                <svg viewBox="0 0 109.34 3.35" style="margin-top: 5px">
                    <path  d="M109.25,1.67l.09,0h-.17L100.4.09,97.58,0H89.11l0,0H50.72l0,0H44.19l0,0h-12l-.13,0H30.19l-.12,0H19.54l0,0H17.8l-.57,0L15.76,0,14.1.13.29,1.65H0l.14,0L0,1.69H.29L13.77,3.34h4s.4,0,.41,0l1.37,0,0,0H30.07l.12,0h1.86l.13,0h12l0,0h6.49l0,0H89.06l0,0,3.2,0s.29,0,.31,0l.73,0,4.9-.08a11.87,11.87,0,0,0,1.57-.13l9.35-1.42h.17Z"/>
                </svg>
                <span>SZELLEM</span>
            </p>
            <div class="menu__poem-category d-flex justify-content-center">
                  <span class="gondolat" @click="selectCategory('Gondolat')"  :class="{ active: menu[0].subCategories[0].active }">

<!--                      <svg viewBox="0 0 110.29 3.85"><path d="M96.65,0l-4,0A5.21,5.21,0,0,1,88,1.56,9,9,0,0,1,83.6.05l-5.68,0a7.16,7.16,0,0,1-1.31.1c-2.91,0-3,1-6.62-.07l-9.33,0a8.81,8.81,0,0,1-4.53,1A9.6,9.6,0,0,1,52.25.17L15.54.31C11,1.53,10.76,1.88,9.41,1.56L6.76.93,0,2.16,11.53,3.85h.28A5.43,5.43,0,0,1,15.92,2.8a9.28,9.28,0,0,1,3.62,1.05H30.07a6.16,6.16,0,0,1,2.11,0h12a7.9,7.9,0,0,1,3.62-.59,7.18,7.18,0,0,1,2.95.59H89.06c3.86-1.06,4.16-1.36,5.43-1.05l4.42,1.05h3.44l7.94-1.92Z"/></svg>-->

                      <svg viewBox="0 0 109.34 3.35" style="margin-top: 9px">
              <path  d="M109.25,1.67l.09,0h-.17L100.4.09,97.58,0H89.11l0,0H50.72l0,0H44.19l0,0h-12l-.13,0H30.19l-.12,0H19.54l0,0H17.8l-.57,0L15.76,0,14.1.13.29,1.65H0l.14,0L0,1.69H.29L13.77,3.34h4s.4,0,.41,0l1.37,0,0,0H30.07l.12,0h1.86l.13,0h12l0,0h6.49l0,0H89.06l0,0,3.2,0s.29,0,.31,0l.73,0,4.9-.08a11.87,11.87,0,0,0,1.57-.13l9.35-1.42h.17Z"/>
          </svg>
                      <span>GONDOLAT</span>
                  </span>
                <span class="intelem" @click="selectCategory('Intelem')" :class="{ active: menu[0].subCategories[1].active }">

<!--                    <svg viewBox="0 0 110.29 3.85"><path d="M96.65,0l-4,0A5.21,5.21,0,0,1,88,1.56,9,9,0,0,1,83.6.05l-5.68,0a7.16,7.16,0,0,1-1.31.1c-2.91,0-3,1-6.62-.07l-9.33,0a8.81,8.81,0,0,1-4.53,1A9.6,9.6,0,0,1,52.25.17L15.54.31C11,1.53,10.76,1.88,9.41,1.56L6.76.93,0,2.16,11.53,3.85h.28A5.43,5.43,0,0,1,15.92,2.8a9.28,9.28,0,0,1,3.62,1.05H30.07a6.16,6.16,0,0,1,2.11,0h12a7.9,7.9,0,0,1,3.62-.59,7.18,7.18,0,0,1,2.95.59H89.06c3.86-1.06,4.16-1.36,5.43-1.05l4.42,1.05h3.44l7.94-1.92Z"/></svg>-->

                    <svg viewBox="0 0 109.34 3.35" style="margin-top: 9px">
              <path  d="M109.25,1.67l.09,0h-.17L100.4.09,97.58,0H89.11l0,0H50.72l0,0H44.19l0,0h-12l-.13,0H30.19l-.12,0H19.54l0,0H17.8l-.57,0L15.76,0,14.1.13.29,1.65H0l.14,0L0,1.69H.29L13.77,3.34h4s.4,0,.41,0l1.37,0,0,0H30.07l.12,0h1.86l.13,0h12l0,0h6.49l0,0H89.06l0,0,3.2,0s.29,0,.31,0l.73,0,4.9-.08a11.87,11.87,0,0,0,1.57-.13l9.35-1.42h.17Z"/>
          </svg>
                    <span>INTELEM</span>
                </span>
            </div>
        </div>
        <div class="poemflex">
            <p class="menu__poem-entry lelek" @click="selectCategory('Lélek')" :class="{ active: menu[1].active }">

<!--                <svg viewBox="0 0 110.29 3.85"><path d="M96.65,0l-4,0A5.21,5.21,0,0,1,88,1.56,9,9,0,0,1,83.6.05l-5.68,0a7.16,7.16,0,0,1-1.31.1c-2.91,0-3,1-6.62-.07l-9.33,0a8.81,8.81,0,0,1-4.53,1A9.6,9.6,0,0,1,52.25.17L15.54.31C11,1.53,10.76,1.88,9.41,1.56L6.76.93,0,2.16,11.53,3.85h.28A5.43,5.43,0,0,1,15.92,2.8a9.28,9.28,0,0,1,3.62,1.05H30.07a6.16,6.16,0,0,1,2.11,0h12a7.9,7.9,0,0,1,3.62-.59,7.18,7.18,0,0,1,2.95.59H89.06c3.86-1.06,4.16-1.36,5.43-1.05l4.42,1.05h3.44l7.94-1.92Z"/></svg>-->

                <svg viewBox="0 0 109.34 3.35" style="margin-top: 5px">
                    <path  d="M109.25,1.67l.09,0h-.17L100.4.09,97.58,0H89.11l0,0H50.72l0,0H44.19l0,0h-12l-.13,0H30.19l-.12,0H19.54l0,0H17.8l-.57,0L15.76,0,14.1.13.29,1.65H0l.14,0L0,1.69H.29L13.77,3.34h4s.4,0,.41,0l1.37,0,0,0H30.07l.12,0h1.86l.13,0h12l0,0h6.49l0,0H89.06l0,0,3.2,0s.29,0,.31,0l.73,0,4.9-.08a11.87,11.87,0,0,0,1.57-.13l9.35-1.42h.17Z"/>
                </svg>
                <span>LÉLEK</span>
            </p>
            <div class="menu__poem-category d-flex justify-content-center">
                  <span class="mamor" @click="selectCategory('Mámor')" :class="{ active: menu[1].subCategories[0].active }">

<!--                      <svg viewBox="0 0 110.29 3.85"><path d="M96.65,0l-4,0A5.21,5.21,0,0,1,88,1.56,9,9,0,0,1,83.6.05l-5.68,0a7.16,7.16,0,0,1-1.31.1c-2.91,0-3,1-6.62-.07l-9.33,0a8.81,8.81,0,0,1-4.53,1A9.6,9.6,0,0,1,52.25.17L15.54.31C11,1.53,10.76,1.88,9.41,1.56L6.76.93,0,2.16,11.53,3.85h.28A5.43,5.43,0,0,1,15.92,2.8a9.28,9.28,0,0,1,3.62,1.05H30.07a6.16,6.16,0,0,1,2.11,0h12a7.9,7.9,0,0,1,3.62-.59,7.18,7.18,0,0,1,2.95.59H89.06c3.86-1.06,4.16-1.36,5.43-1.05l4.42,1.05h3.44l7.94-1.92Z"/></svg>-->

                      <svg viewBox="0 0 109.34 3.35" style="margin-top: 9px">
              <path  d="M109.25,1.67l.09,0h-.17L100.4.09,97.58,0H89.11l0,0H50.72l0,0H44.19l0,0h-12l-.13,0H30.19l-.12,0H19.54l0,0H17.8l-.57,0L15.76,0,14.1.13.29,1.65H0l.14,0L0,1.69H.29L13.77,3.34h4s.4,0,.41,0l1.37,0,0,0H30.07l.12,0h1.86l.13,0h12l0,0h6.49l0,0H89.06l0,0,3.2,0s.29,0,.31,0l.73,0,4.9-.08a11.87,11.87,0,0,0,1.57-.13l9.35-1.42h.17Z"/>
          </svg>
                      <span>MÁMOR</span>
                  </span>
                <span class="banat" @click="selectCategory('Bánat')" :class="{ active: menu[1].subCategories[1].active }">

<!--                    <svg viewBox="0 0 110.29 3.85"><path d="M96.65,0l-4,0A5.21,5.21,0,0,1,88,1.56,9,9,0,0,1,83.6.05l-5.68,0a7.16,7.16,0,0,1-1.31.1c-2.91,0-3,1-6.62-.07l-9.33,0a8.81,8.81,0,0,1-4.53,1A9.6,9.6,0,0,1,52.25.17L15.54.31C11,1.53,10.76,1.88,9.41,1.56L6.76.93,0,2.16,11.53,3.85h.28A5.43,5.43,0,0,1,15.92,2.8a9.28,9.28,0,0,1,3.62,1.05H30.07a6.16,6.16,0,0,1,2.11,0h12a7.9,7.9,0,0,1,3.62-.59,7.18,7.18,0,0,1,2.95.59H89.06c3.86-1.06,4.16-1.36,5.43-1.05l4.42,1.05h3.44l7.94-1.92Z"/></svg>-->


                        <svg viewBox="0 0 109.34 3.35" style="margin-top: 9px">
                    <path  d="M109.25,1.67l.09,0h-.17L100.4.09,97.58,0H89.11l0,0H50.72l0,0H44.19l0,0h-12l-.13,0H30.19l-.12,0H19.54l0,0H17.8l-.57,0L15.76,0,14.1.13.29,1.65H0l.14,0L0,1.69H.29L13.77,3.34h4s.4,0,.41,0l1.37,0,0,0H30.07l.12,0h1.86l.13,0h12l0,0h6.49l0,0H89.06l0,0,3.2,0s.29,0,.31,0l.73,0,4.9-.08a11.87,11.87,0,0,0,1.57-.13l9.35-1.42h.17Z"/>
                </svg>

                    <span>BÁNAT</span>
                </span>


            </div>
        </div>
        <div class="poemflex">
            <p class="menu__poem-entry test" @click="selectCategory('Test')" :class="{ active: menu[2].active }">

<!--                <svg viewBox="0 0 110.29 3.85"><path d="M96.65,0l-4,0A5.21,5.21,0,0,1,88,1.56,9,9,0,0,1,83.6.05l-5.68,0a7.16,7.16,0,0,1-1.31.1c-2.91,0-3,1-6.62-.07l-9.33,0a8.81,8.81,0,0,1-4.53,1A9.6,9.6,0,0,1,52.25.17L15.54.31C11,1.53,10.76,1.88,9.41,1.56L6.76.93,0,2.16,11.53,3.85h.28A5.43,5.43,0,0,1,15.92,2.8a9.28,9.28,0,0,1,3.62,1.05H30.07a6.16,6.16,0,0,1,2.11,0h12a7.9,7.9,0,0,1,3.62-.59,7.18,7.18,0,0,1,2.95.59H89.06c3.86-1.06,4.16-1.36,5.43-1.05l4.42,1.05h3.44l7.94-1.92Z"/></svg>-->
                <svg viewBox="0 0 109.34 3.35" style="margin-top: 5px">
                    <path  d="M109.25,1.67l.09,0h-.17L100.4.09,97.58,0H89.11l0,0H50.72l0,0H44.19l0,0h-12l-.13,0H30.19l-.12,0H19.54l0,0H17.8l-.57,0L15.76,0,14.1.13.29,1.65H0l.14,0L0,1.69H.29L13.77,3.34h4s.4,0,.41,0l1.37,0,0,0H30.07l.12,0h1.86l.13,0h12l0,0h6.49l0,0H89.06l0,0,3.2,0s.29,0,.31,0l.73,0,4.9-.08a11.87,11.87,0,0,0,1.57-.13l9.35-1.42h.17Z"/>
                </svg>

                <span>TEST</span>
            </p>
            <div class="menu__poem-category d-flex justify-content-center">
                  <span class="itthon" @click="selectCategory('Itthon')" :class="{ active: menu[2].subCategories[0].active }">

<!--                      <svg viewBox="0 0 110.29 3.85"><path d="M96.65,0l-4,0A5.21,5.21,0,0,1,88,1.56,9,9,0,0,1,83.6.05l-5.68,0a7.16,7.16,0,0,1-1.31.1c-2.91,0-3,1-6.62-.07l-9.33,0a8.81,8.81,0,0,1-4.53,1A9.6,9.6,0,0,1,52.25.17L15.54.31C11,1.53,10.76,1.88,9.41,1.56L6.76.93,0,2.16,11.53,3.85h.28A5.43,5.43,0,0,1,15.92,2.8a9.28,9.28,0,0,1,3.62,1.05H30.07a6.16,6.16,0,0,1,2.11,0h12a7.9,7.9,0,0,1,3.62-.59,7.18,7.18,0,0,1,2.95.59H89.06c3.86-1.06,4.16-1.36,5.43-1.05l4.42,1.05h3.44l7.94-1.92Z"/></svg>-->
                        <svg viewBox="0 0 109.34 3.35" style="margin-top: 9px">
                    <path  d="M109.25,1.67l.09,0h-.17L100.4.09,97.58,0H89.11l0,0H50.72l0,0H44.19l0,0h-12l-.13,0H30.19l-.12,0H19.54l0,0H17.8l-.57,0L15.76,0,14.1.13.29,1.65H0l.14,0L0,1.69H.29L13.77,3.34h4s.4,0,.41,0l1.37,0,0,0H30.07l.12,0h1.86l.13,0h12l0,0h6.49l0,0H89.06l0,0,3.2,0s.29,0,.31,0l.73,0,4.9-.08a11.87,11.87,0,0,0,1.57-.13l9.35-1.42h.17Z"/>
                </svg>
                  <span>ITTHON</span>
                  </span>
                <span class="kulfold" @click="selectCategory('Külföld')" :class="{ active: menu[2].subCategories[1].active }">

<!--                    <svg viewBox="0 0 110.29 3.85"><path d="M96.65,0l-4,0A5.21,5.21,0,0,1,88,1.56,9,9,0,0,1,83.6.05l-5.68,0a7.16,7.16,0,0,1-1.31.1c-2.91,0-3,1-6.62-.07l-9.33,0a8.81,8.81,0,0,1-4.53,1A9.6,9.6,0,0,1,52.25.17L15.54.31C11,1.53,10.76,1.88,9.41,1.56L6.76.93,0,2.16,11.53,3.85h.28A5.43,5.43,0,0,1,15.92,2.8a9.28,9.28,0,0,1,3.62,1.05H30.07a6.16,6.16,0,0,1,2.11,0h12a7.9,7.9,0,0,1,3.62-.59,7.18,7.18,0,0,1,2.95.59H89.06c3.86-1.06,4.16-1.36,5.43-1.05l4.42,1.05h3.44l7.94-1.92Z"/></svg>-->
                        <svg viewBox="0 0 109.34 3.35" style="margin-top: 9px">
                    <path  d="M109.25,1.67l.09,0h-.17L100.4.09,97.58,0H89.11l0,0H50.72l0,0H44.19l0,0h-12l-.13,0H30.19l-.12,0H19.54l0,0H17.8l-.57,0L15.76,0,14.1.13.29,1.65H0l.14,0L0,1.69H.29L13.77,3.34h4s.4,0,.41,0l1.37,0,0,0H30.07l.12,0h1.86l.13,0h12l0,0h6.49l0,0H89.06l0,0,3.2,0s.29,0,.31,0l.73,0,4.9-.08a11.87,11.87,0,0,0,1.57-.13l9.35-1.42h.17Z"/>
                </svg>

                    <span>KÜLFÖLD</span>
                </span>
            </div>
        </div>
<!--        </div>-->


    </div>
</template>

<script>
// import {gsap} from "gsap";

import {gsap} from "gsap";
export default {
    name: "MenuPoem",
    computed: {
        menuColor: function () {
            let value;
            if (this.$store.getters.mode === 'light') {
                value = 'black';
            } else {
                value = 'white';
            }
            return value;
        },
        route: function () {
            return this.$route.path;
        },
        oldRoute: function (){
            return this.oldPath;
        },
        menuState: function (){
            let returnval = false;
            let menu = this.$store.getters.menuPoem;
            menu.forEach(e => {
                if(e.active){
                    returnval = true;
                }
            });
            return returnval;
        }
    },

    watch: {
        'oldRoute' (newValue, oldValue){
            if(newValue === '' && oldValue === null ){

                    let szellem = document.querySelector('.menupoem .szellem');
                    let lelek = document.querySelector('.menupoem .lelek');
                    let test = document.querySelector('.menupoem .test');



                    gsap.timeline()
                        .to(szellem, {autoAlpha: 1, duration: 0.333, stagger: 0.1}, '+=1.5')
                        .to(lelek, {autoAlpha: 1, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(test, {autoAlpha: 1, duration: 0.333, stagger: 0.1, onComplete: () => {
                                let alapmenu = this.menu;
                                let count = 0;
                                alapmenu.forEach(e => {
                                    if(e.active) count++;
                                    e.subCategories.forEach(sub => {
                                        if(sub.active) count++;
                                    })
                                })
                                if(count === 0){

                                    let menu = this.loadedPoem;
                                    menu.forEach(e => {
                                        if(e.active){
                                            this.selectCategory(e.name);
                                        }
                                        e.subCategories.forEach(sub => {
                                            if(sub.active){
                                                this.selectCategory(sub.name);
                                            }
                                        })
                                    })
                                }
                            }}, '-=0.15');



            }
        },
        async 'route'(newValue, oldValue) {
            let split = this.route.split('/');
            this.loadedActive = !!split[2] && split[2].length > 0;

            this.oldPath = oldValue;
            // if (to.path.includes('versek') && !to.path.includes('admin')) {
            //
            // }
            // let subs = document.querySelectorAll('.menu__poem-category span');

            let szellem = document.querySelector('.menupoem .szellem');
            let lelek = document.querySelector('.menupoem .lelek');
            let test = document.querySelector('.menupoem .test');
            let gondolat = document.querySelector('.menupoem .gondolat');
            let intelem = document.querySelector('.menupoem .intelem');
            let mamor = document.querySelector('.menupoem .mamor');
            let banat = document.querySelector('.menupoem .banat');
            let itthon = document.querySelector('.menupoem .itthon');
            let kulfold = document.querySelector('.menupoem .kulfold');
            if(newValue === '/versek' && !newValue.includes('/admin')){
                this.loadedPoem = [];
                this.verslista = false;
                gsap.timeline()
                    .to(szellem, {autoAlpha: 1, duration: 0.333, stagger: 0.1})
                    .to(lelek, {autoAlpha: 1, duration: 0.333, stagger: 0.1}, '-=0.15')
                    .to(test, {autoAlpha: 1, duration: 0.333, stagger: 0.1}, '-=0.15')
            }

            if(oldValue === '/versek' && newValue.includes('versek') && !newValue.includes('/admin')){
                this.verslista = true;



            }

            if(newValue === '/versek' && oldValue.includes('versek') && !newValue.includes('/admin')){
                this.loadedPoem = [];
                this.verslista = false;
                this.clearAll();
            }

            if(!newValue.includes('/versek') && oldValue.includes('versek') && !newValue.includes('/admin')){
                this.loadedPoem = [];
                this.verslista = false;
                let menu = this.menu;


                if(!menu[0].active && !menu[1].active && !menu[2].active){
                    gsap.timeline()
                        .to(test, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(lelek, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(szellem, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                }
                if(menu[0].active && !menu[1].active && !menu[2].active){
                    gsap.timeline()
                        .to(test, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(lelek, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(intelem, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(gondolat, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(szellem, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                }
                if(!menu[0].active && menu[1].active && !menu[2].active){
                    gsap.timeline()
                        .to(test, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(banat, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(mamor, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(lelek, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(szellem, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                }
                if(!menu[0].active && !menu[1].active && menu[2].active){
                    gsap.timeline()
                        .to(kulfold, {autoAlpha: 0, duration: 0.333, stagger: 0.1})
                        .to(itthon, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(test, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(lelek, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(szellem, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                }
                if(menu[0].active && menu[1].active && !menu[2].active){
                    gsap.timeline()
                        .to(test, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(banat, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(mamor, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(lelek, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(intelem, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(gondolat, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(szellem, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                }
                if(menu[0].active && !menu[1].active && menu[2].active){
                    gsap.timeline()
                        .to(kulfold, {autoAlpha: 0, duration: 0.333, stagger: 0.1})
                        .to(itthon, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(test, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(lelek, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(intelem, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(gondolat, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(szellem, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                }
                if(!menu[0].active && menu[1].active && menu[2].active){
                    gsap.timeline()
                        .to(kulfold, {autoAlpha: 0, duration: 0.333, stagger: 0.1})
                        .to(itthon, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(test, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(banat, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(mamor, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(lelek, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(szellem, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                }
                if(menu[0].active && menu[1].active && menu[2].active){
                    gsap.timeline()
                        .to(kulfold, {autoAlpha: 0, duration: 0.333, stagger: 0.1})
                        .to(itthon, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(test, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(banat, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(mamor, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(lelek, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(intelem, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(gondolat, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        .to(szellem, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                }

                menu.forEach(e => {
                    e.active = false;
                    e.subCategories.forEach(sub => {
                        sub.active = false;
                    })
                })

                this.menu = menu;
                this.$store.commit('setMenuPoem', menu)
                this.$store.commit('setLogoColor', 'alap');
            }
        },

    },
    data() {
        return {
            oldPath: null,
            loadedActive: false,
            verslista: false,
            loadedPoem: [],
            menu: [
                {
                    "_id": "645bbb1c5040a4e2ed5c633a",
                    "name": "Szellem",
                    "color": "#19428C",
                    "subCategories": [
                        {
                            "name": "Gondolat",
                            "color": "#19428C",
                            "active": false
                        },
                        {
                            "name": "Intelem",
                            "color": "#19428C",
                            "active": false
                        }
                    ],
                    "__v": 0,
                    "active": false
                },
                {
                    "_id": "645bbb1c5040a4e2ed5c633e",
                    "name": "Lélek",
                    "color": "#7B6D00",
                    "subCategories": [
                        {
                            "name": "Mámor",
                            "color": "#7B6D00",
                            "active": false
                        },
                        {
                            "name": "Bánat",
                            "color": "#7B6D00",
                            "active": false
                        }
                    ],
                    "__v": 0,
                    "active": false
                },
                {
                    "_id": "645bbb1c5040a4e2ed5c633c",
                    "name": "Test",
                    "color": "#823033",
                    "subCategories": [
                        {
                            "name": "Itthon",
                            "color": "#823033",
                            "active": false
                        },
                        {
                            "name": "Külföld",
                            "color": "#823033",
                            "active": false
                        }
                    ],
                    "__v": 0,
                    "active": false
                }
            ],
        }
    },
    methods: {
        swipeDown(){
            let menu = document.getElementById('menu__poem');

            gsap.timeline()
                .to(menu, {autoAlpha: 0, duration: 0.333, zIndex: 1, stagger: 0.1})
        },
        clearAll(){
            let menu = this.menu;
            menu.forEach(e => {



                    let element1;
                    let element2;

                    let gondolat;
                    let intelem;
                    let mamor;
                    let banat;
                    let itthon;
                    let kulfold;
                    if(this.mobileCheck()){
                        if (/iPad/.test(navigator.platform)) {
                            gondolat = document.querySelector('.menupoem  .gondolat');
                            intelem = document.querySelector('.menupoem  .intelem');
                            mamor = document.querySelector('.menupoem  .mamor');
                            banat = document.querySelector('.menupoem  .banat');
                            itthon = document.querySelector('.menupoem  .itthon');
                            kulfold = document.querySelector('.menupoem  .kulfold');
                        } else{
                            gondolat = document.querySelector('.menupoemmobile  .gondolat');
                            intelem = document.querySelector('.menupoemmobile  .intelem');
                            mamor = document.querySelector('.menupoemmobile  .mamor');
                            banat = document.querySelector('.menupoemmobile  .banat');
                            itthon = document.querySelector('.menupoemmobile  .itthon');
                            kulfold = document.querySelector('.menupoemmobile  .kulfold');
                        }

                    } else{
                        gondolat = document.querySelector('.menupoem  .gondolat');
                        intelem = document.querySelector('.menupoem  .intelem');
                        mamor = document.querySelector('.menupoem  .mamor');
                        banat = document.querySelector('.menupoem  .banat');
                        itthon = document.querySelector('.menupoem  .itthon');
                        kulfold = document.querySelector('.menupoem  .kulfold');
                    }

                    if(e.name === 'Szellem'){
                        element1 = gondolat;
                        element2 = intelem;
                    }
                    if(e.name === 'Lélek'){
                        element1 = mamor;
                        element2 = banat;
                    }
                    if(e.name === 'Test'){
                        element1 = itthon;
                        element2 = kulfold;
                    }

                    if (e.active) {
                        e.subCategories.forEach(sub => {
                            sub.active = false;
                        })
                        gsap.timeline()
                            .to(element2, {autoAlpha: 0, duration: 0.333, stagger: 0.1})
                            .to(element1, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        e.active = false;
                    }

            })



            this.menu = menu;
            if(!menu[0].active && !menu[1].active && !menu[2].active){
                this.$store.commit('setLogoColor', 'alap');
            }
            if(menu[0].active && !menu[1].active && !menu[2].active){
                this.$store.commit('setLogoColor', 'szellem');
            }
            if(!menu[0].active && menu[1].active && !menu[2].active){
                this.$store.commit('setLogoColor', 'lelek');
            }
            if(!menu[0].active && !menu[1].active && menu[2].active){
                this.$store.commit('setLogoColor', 'test');
            }
            if(menu[0].active && menu[1].active && !menu[2].active){
                this.$store.commit('setLogoColor', 'szellemlelek');
            }
            if(menu[0].active && !menu[1].active && menu[2].active){
                this.$store.commit('setLogoColor', 'szellemtest');
            }
            if(!menu[0].active && menu[1].active && menu[2].active){
                this.$store.commit('setLogoColor', 'lelektest');
            }
            if(menu[0].active && menu[1].active && menu[2].active){
                this.$store.commit('setLogoColor', 'szellemlelektest');
            }
            // this.menu = menu;
            this.$store.commit('setMenuPoem', menu);

        },
        async getCategories() {
            let result = await this.apiRequest('GET', '/category/all');
            if (result?.data) {
                let categories = [];
                result.data.forEach(e => {
                    e.active = false;
                    e.subCategories.forEach(j => {
                        j.active = false;
                    })
                    if (e.name === 'Szellem') categories[0] = e;
                    if (e.name === 'Lélek') categories[1] = e;
                    if (e.name === 'Test') categories[2] = e;
                })
                this.menu = categories;


                // this.$store.commit('setMenuPoem', categories);
            }
        },
        async loadContent() {
            let result = await this.apiRequest('GET', '/content/all');
            if (result?.data) {
                this.$store.commit('setColors', result.data[0].colors);

                if(this.$store.getters.mode === 'dark'){
                    document.documentElement.style.setProperty('--poem_szellem', result.data[0].colors[0].colorDark);
                    document.documentElement.style.setProperty('--poem_lelek', result.data[0].colors[1].colorDark);
                    document.documentElement.style.setProperty('--poem_test', result.data[0].colors[2].colorDark);
                    document.documentElement.style.setProperty('--poem_szellemlelek', result.data[0].colors[3].colorDark);
                    document.documentElement.style.setProperty('--poem_szellemtest', result.data[0].colors[4].colorDark);
                    document.documentElement.style.setProperty('--poem_lelektest', result.data[0].colors[5].colorDark);
                    document.documentElement.style.setProperty('--poem_szellemlelektest', result.data[0].colors[6].colorDark);
                } else{
                    document.documentElement.style.setProperty('--poem_szellem', result.data[0].colors[0].colorLight);
                    document.documentElement.style.setProperty('--poem_lelek', result.data[0].colors[1].colorLight);
                    document.documentElement.style.setProperty('--poem_test', result.data[0].colors[2].colorLight);
                    document.documentElement.style.setProperty('--poem_szellemlelek', result.data[0].colors[3].colorLight);
                    document.documentElement.style.setProperty('--poem_szellemtest', result.data[0].colors[4].colorLight);
                    document.documentElement.style.setProperty('--poem_lelektest', result.data[0].colors[5].colorLight);
                    document.documentElement.style.setProperty('--poem_szellemlelektest', result.data[0].colors[6].colorLight);
                }
            }
        },
        setLogo(){

        },
        mobileCheck() {
            if( screen.width <= 1023 ) {
                return true;
            }
            else {
                return false;
            }
        },


        selectCategory(name) {

            if(!this.loadedActive){
                let menu = this.menu;

                menu.forEach(e => {

                    if (e.name === name) {

                        e.active = !e.active;
                        let element1;
                        let element2;

                        let gondolat;
                        let intelem;
                        let mamor;
                        let banat;
                        let itthon;
                        let kulfold;
                        if(this.mobileCheck()){
                            if (/iPad/.test(navigator.platform)) {
                                gondolat = document.querySelector('.menupoem  .gondolat');
                                intelem = document.querySelector('.menupoem  .intelem');
                                mamor = document.querySelector('.menupoem  .mamor');
                                banat = document.querySelector('.menupoem  .banat');
                                itthon = document.querySelector('.menupoem  .itthon');
                                kulfold = document.querySelector('.menupoem  .kulfold');
                            } else{
                                gondolat = document.querySelector('.menupoemmobile  .gondolat');
                                intelem = document.querySelector('.menupoemmobile  .intelem');
                                mamor = document.querySelector('.menupoemmobile  .mamor');
                                banat = document.querySelector('.menupoemmobile  .banat');
                                itthon = document.querySelector('.menupoemmobile  .itthon');
                                kulfold = document.querySelector('.menupoemmobile  .kulfold');
                            }

                        } else{
                            gondolat = document.querySelector('.menupoem  .gondolat');
                            intelem = document.querySelector('.menupoem  .intelem');
                            mamor = document.querySelector('.menupoem  .mamor');
                            banat = document.querySelector('.menupoem  .banat');
                            itthon = document.querySelector('.menupoem  .itthon');
                            kulfold = document.querySelector('.menupoem  .kulfold');
                        }

                        if(name === 'Szellem'){
                            element1 = gondolat;
                            element2 = intelem;
                        }
                        if(name === 'Lélek'){
                            element1 = mamor;
                            element2 = banat;
                        }
                        if(name === 'Test'){
                            element1 = itthon;
                            element2 = kulfold;
                        }

                        if (!e.active) {
                            e.subCategories.forEach(sub => {
                                sub.active = false;
                            })
                            gsap.timeline()
                                .to(element2, {autoAlpha: 0, duration: 0.333, stagger: 0.1})
                                .to(element1, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')
                        } else{

                            gsap.timeline()
                                .to(element1, {autoAlpha: 1, duration: 0.333, stagger: 0.1})
                                .to(element2, {autoAlpha: 1, duration: 0.333, stagger: 0.1}, '-=0.15')


                        }
                    } else {
                        e.subCategories.forEach(sub => {
                            if (sub.name === name) {
                                sub.active = !sub.active;
                                // let element;
                                // if(name === 'Gondolat') element = document.querySelector('.gondolat');
                                // if(name === 'Intelem') element = document.querySelector('.intelem');
                                // if(name === 'Mámor') element = document.querySelector('.mamor');
                                // if(name === 'Bánat') element = document.querySelector('.banat');
                                // if(name === 'Itthon') element = document.querySelector('.itthon');
                                // if(name === 'Külföld') element = document.querySelector('.kulfold');
                                // if(sub.active){
                                //     gsap.timeline()
                                //         .to(element, {autoAlpha: 1, duration: 0.666, stagger: 0.1})
                                // } else{
                                //     gsap.timeline()
                                //         .to(element, {autoAlpha: 0, duration: 0.666, stagger: 0.1})
                                // }
                            }
                        })
                    }

                })



                this.menu = menu;
                if(!menu[0].active && !menu[1].active && !menu[2].active){
                    this.$store.commit('setLogoColor', 'alap');
                }
                if(menu[0].active && !menu[1].active && !menu[2].active){
                    this.$store.commit('setLogoColor', 'szellem');
                }
                if(!menu[0].active && menu[1].active && !menu[2].active){
                    this.$store.commit('setLogoColor', 'lelek');
                }
                if(!menu[0].active && !menu[1].active && menu[2].active){
                    this.$store.commit('setLogoColor', 'test');
                }
                if(menu[0].active && menu[1].active && !menu[2].active){
                    this.$store.commit('setLogoColor', 'szellemlelek');
                }
                if(menu[0].active && !menu[1].active && menu[2].active){
                    this.$store.commit('setLogoColor', 'szellemtest');
                }
                if(!menu[0].active && menu[1].active && menu[2].active){
                    this.$store.commit('setLogoColor', 'lelektest');
                }
                if(menu[0].active && menu[1].active && menu[2].active){
                    this.$store.commit('setLogoColor', 'szellemlelektest');
                }
                // this.menu = menu;
                this.$store.commit('setMenuPoem', menu);
            }

        },
        swipeUp() {
            document.getElementById('menu__poem').style.opacity = 1;
        },
        loadColors(){

        },
    },
    // },
    async mounted() {
        if(this.oldPath === null &&  this.route.includes('/versek') &&  !this.route.includes('/admin')){
            this.oldPath = '';

            // let split = this.route.split('/');

        }


        document.addEventListener("poemLoaded", (e) => {
            this.loadedPoem = e.detail.data.categories;

            if(this.verslista){


                let element1;
                let element2;

                let gondolat;
                let intelem;
                let mamor;
                let banat;
                let itthon;
                let kulfold;
                if(this.mobileCheck()){
                    if (/MacIntel/.test(navigator.platform)) {
                        gondolat = document.querySelector('.menupoem  .gondolat');
                        intelem = document.querySelector('.menupoem  .intelem');
                        mamor = document.querySelector('.menupoem  .mamor');
                        banat = document.querySelector('.menupoem  .banat');
                        itthon = document.querySelector('.menupoem  .itthon');
                        kulfold = document.querySelector('.menupoem  .kulfold');
                    } else{
                        gondolat = document.querySelector('.menupoemmobile  .gondolat');
                        intelem = document.querySelector('.menupoemmobile  .intelem');
                        mamor = document.querySelector('.menupoemmobile  .mamor');
                        banat = document.querySelector('.menupoemmobile  .banat');
                        itthon = document.querySelector('.menupoemmobile  .itthon');
                        kulfold = document.querySelector('.menupoemmobile  .kulfold');
                    }

                } else{
                    gondolat = document.querySelector('.menupoem  .gondolat');
                    intelem = document.querySelector('.menupoem  .intelem');
                    mamor = document.querySelector('.menupoem  .mamor');
                    banat = document.querySelector('.menupoem  .banat');
                    itthon = document.querySelector('.menupoem  .itthon');
                    kulfold = document.querySelector('.menupoem  .kulfold');
                }

                for(let i = 0; i < this.loadedPoem.length; i++){
                    if(this.menu[i].name === 'Szellem'){
                        element1 = gondolat;
                        element2 = intelem;
                    }
                    if(this.menu[i].name === 'Lélek'){
                        element1 = mamor;
                        element2 = banat;
                    }
                    if(this.menu[i].name === 'Test'){
                        element1 = itthon;
                        element2 = kulfold;
                    }

                    if(this.loadedPoem[i].active && !this.menu[i].active){
                        this.menu[i].active = true;

                        gsap.timeline()
                            .to(element1, {autoAlpha: 1, duration: 0.333, stagger: 0.1})
                            .to(element2, {autoAlpha: 1, duration: 0.333, stagger: 0.1}, '-=0.15')

                        this.selectCategory(this.menu[i].name);
                    }
                    if(!this.loadedPoem[i].active && this.menu[i].active){
                        this.menu[i].active = false;

                        gsap.timeline()
                            .to(element1, {autoAlpha: 0, duration: 0.333, stagger: 0.1})
                            .to(element2, {autoAlpha: 0, duration: 0.333, stagger: 0.1}, '-=0.15')

                        this.selectCategory(this.menu[i].name);
                    }

                    if(this.menu[i].active){
                        for(let j = 0; j < this.loadedPoem[i].subCategories.length; j++){
                            if(this.loadedPoem[i].subCategories[j].active && !this.menu[i].subCategories[j].active){
                                this.menu[i].subCategories[j].active = true;
                                this.selectCategory(this.menu[i].subCategories[j].name);
                            }
                            if(!this.loadedPoem[i].subCategories[j].active && this.menu[i].subCategories[j].active){
                                this.menu[i].subCategories[j].active = false;
                                this.selectCategory(this.menu[i].subCategories[j].name);
                            }
                        }
                    }



                }

                let menu = this.menu;
                if(!menu[0].active && !menu[1].active && !menu[2].active){
                    this.$store.commit('setLogoColor', 'alap');
                }
                if(menu[0].active && !menu[1].active && !menu[2].active){
                    this.$store.commit('setLogoColor', 'szellem');
                }
                if(!menu[0].active && menu[1].active && !menu[2].active){
                    this.$store.commit('setLogoColor', 'lelek');
                }
                if(!menu[0].active && !menu[1].active && menu[2].active){
                    this.$store.commit('setLogoColor', 'test');
                }
                if(menu[0].active && menu[1].active && !menu[2].active){
                    this.$store.commit('setLogoColor', 'szellemlelek');
                }
                if(menu[0].active && !menu[1].active && menu[2].active){
                    this.$store.commit('setLogoColor', 'szellemtest');
                }
                if(!menu[0].active && menu[1].active && menu[2].active){
                    this.$store.commit('setLogoColor', 'lelektest');
                }
                if(menu[0].active && menu[1].active && menu[2].active){
                    this.$store.commit('setLogoColor', 'szellemlelektest');
                }
                // this.menu = menu;
                this.$store.commit('setMenuPoem', menu);

                // let alapmenu = this.menu;
                // let count = 0;
                // alapmenu.forEach(e => {
                //     if(e.active) count++;
                //     e.subCategories.forEach(sub => {
                //         if(sub.active) count++;
                //     })
                // })
                // if(count === 0){
                //     console.log('ideertmarezamenumostmar')
                //     let menu = this.loadedPoem;
                //     menu.forEach(e => {
                //         if(e.active){
                //             this.selectCategory(e.name);
                //         }
                //         e.subCategories.forEach(sub => {
                //             if(sub.active){
                //                 this.selectCategory(sub.name);
                //             }
                //         })
                //     })
                // }


            }




            if(this.oldPath === null &&  this.route.includes('/versek') &&  !this.route.includes('/admin')){




                this.oldPath = '';
                let split = this.route.split('/');

                if(!!split[2] && split[2].length > 0){
                    this.loadedActive = true;
                }
            }
        });

        await this.getCategories();


    },
}
</script>

<style scoped>

.login__icon_clear_email path {
    fill: var(--grey);
    opacity: 1;
    transition: ease all 0.2s;
}
.login__icon_clear_email {
    position: absolute;
    cursor: pointer;
    width: 14.47px;
    height: 14.47px;
    margin: auto;
    left: 0;
    right: 0;
    top: -35px;
    z-index: 100;
}

.login__icon_clear_email:hover .login__icon_clear_email-path{
    fill: var(--admin_comment_grey);
}
.gondolat, .intelem, .mamor, .banat, .itthon, .kulfold{
    visibility: hidden;
}
.menu__poem-entry{
    position: relative;
    visibility: hidden;
}

.menu__poem-entry svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    top: 0;
    height: 20px;
}

.menu__poem-entry svg path {
    fill: transparent;
    stroke-width: 1px;
    //opacity: 0.27;
    stroke-dasharray: 547.4111328125;
    stroke-dashoffset: 547.4111328125;
    transition: all 1s cubic-bezier(0.29, 0.68, 0.74, 1.02);
}

.nohover{
    pointer-events: none;
}

 .szellem svg path, .gondolat svg path, .intelem svg path{
    stroke: var(--poem_szellem);
}

.lelek svg path, .mamor svg path, .banat svg path{
    stroke: var(--poem_lelek);
}

.test svg path, .itthon svg path, .kulfold svg path{
    stroke: var(--poem_test);
}

.szellem:hover svg path, .gondolat:hover svg path, .intelem:hover svg path {
    fill: var(--poem_szellem);
}

.lelek:hover svg path, .mamor:hover svg path, .banat:hover svg path {
    fill: var(--poem_lelek);
}

.test:hover svg path, .itthon:hover svg path, .kulfold:hover svg path {
    fill: var(--poem_test);
}

.menu__poem-entry:hover svg path {
    stroke-dashoffset: 0;
}

.active svg path{
    stroke-dashoffset: 0;
}

span svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -7px;
    width: 100%;
    height: 20px;
}

span svg path {
    fill: transparent;
    stroke-width: 1px;
    //opacity: 0.27;
    stroke-dasharray: 547.4111328125;
    stroke-dashoffset: 547.4111328125;
    transition: all 1s cubic-bezier(0.29, 0.68, 0.74, 1.02);
}

span:hover svg path {
    stroke-dashoffset: 0;
    fill: var(--grey)
}

#menu__poem {
    position: fixed;
    text-align: center;
    bottom: 0px;
    left: 0px;
    width: 320px;
    height: 32.5vh;
//background-color: #e74c3c; z-index: 10;
}

.menu__poem_visible {
    visibility: visible !important;
}

.menu__poem-entry span{
    margin-bottom: 0;
    font-family: El Messiri, serif;
    font-size: 18pt;
    letter-spacing: 0.2px;
    cursor: pointer;
    z-index: 11;
    color: var(--color);
//transition: 0.666s ease all; -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.menu__poem-category {
    margin-bottom: 20px;
//visibility: hidden;
}

.menu__poem-category span{
    color: var(--color)
}

span {

    position: relative;
    font-family: El Messiri, serif;
    font-size: 18px;
    letter-spacing: 0.2px;
    margin-right: 5px;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    //transition: 0.666s ease all;
}
.szellem svg{
    width: 165px;
}

.lelek svg{
    width: 135px;
}

.test svg{
    width: 100px;
}

@media (min-width: 1024px){
    .mobileclose{
        display: none;

    }
}
/*@media (min-width: 1024px) and (max-width: 1440px) {
    .menu__poem-entry{
        margin-bottom: 0 !important;
    }

    .menu__poem-entry span{
        font-size: 16pt !important;
    }

    .menu__poem-category{
        margin-bottom: 5px !important;
    }
}

@media (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1920px){
    .menu__poem-entry{
        margin-bottom: 0 !important;
    }

    .menu__poem-entry span{
        font-size: 16pt !important;
    }

    .menu__poem-category{
        margin-bottom: 5px !important;
    }
}*/

@media (min-width: 1440px)  {
    #menu__poem {
        height: fit-content;
        bottom: 45px;
    }

    .menu__poem-entry{
        margin-bottom: 0 !important;
    }
}

@media (min-width: 1024px)  and (max-width: 1439px){
    .menu__poem-category{
        margin-bottom: 5px !important;
    }

    #menu__poem {
        height: fit-content;
        bottom: 20px;
    }

    .menu__poem-entry{
        margin-bottom: 0 !important;
    }
}
@media screen and (max-width: 1023px) {
    .mobileclose{
        font-family: El Messiri, serif;
        font-size: 18px;
        color: var(--grey);
        margin-top: -30px;
        user-select: none;
    }
    .login__icon_clear_email {
        top: 9px !important;
        bottom: 0px !important;
        position: absolute;
        cursor: pointer;
        width: 14.47px;
        height: 14.47px;
        margin: auto;
        right: 30px;
        left: initial !important;
        z-index: 100;
    }


    .menu__poem-entry{
        margin-bottom: 0;
    }
    #menu__poem {
        left: 0;
        right: 0;
        margin: auto;
        width: 100vw;
        bottom: 0;
        visibility: hidden;
        height: fit-content;
        background-color: var(--bg);
        padding-top: 50px;
    }


    .menu__poem-entry{
        //position: relative;
        visibility: hidden;
    }

   /* .gondolat, .intelem, .mamor, .banat, .itthon, .kulfold{
        visibility: hidden;
    }

    .poemflex{
        display: flex;
    }

    .menu__poem-category span{
        margin-left: 2px;
        margin-top: 4px;
    }
    .szellem svg{
        width: 100px;
    }

    .lelek svg{
        width: 70px;
    }

    .test svg{
        width: 55px;
    }*/
}
</style>