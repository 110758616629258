<template>
    <div id="app">
        <AdminBackground/>
        <transition name="fade" mode="out-in">
            <router-view :key="$route.fullPath"/>
        </transition>


        <div v-if="$route.name !== 'login' && $route.name !== 'welcome'">

            <Logo/>
            <MobileMenu/>

            <div class="darklightdesktop" v-if="!$route.path.includes('/admin')">
                <DarkLightSwitcher/>

            </div>
            <Hamburger/>
            <Menu/>
        </div>

<!--        <div v-if="$route.path.includes('/versek') && !$route.path.includes('/admin')">-->
        <div class="menupoem">
            <MenuPoem/>
        </div>

<!--        </div>-->

        <Comments/>
<!--        <div v-if="!$route.path.includes('/admin')">-->

<!--        </div>-->


        <div v-if="$route.name === 'poem'" class="gradient__bottom"></div>
    </div>
</template>

<script>
import Logo from "@/components/Logo";
import Menu from "@/components/Menu";
import MenuPoem from "@/components/MenuPoem";
import Hamburger from "@/components/Hamburger";
import MobileMenu from "@/components/MobileMenu";
import AdminBackground from "@/components/AdminBackground.vue";
import Comments from "@/components/Comments.vue";
import DarkLightSwitcher from "@/components/DarkLightSwitcher.vue";

export default {
    data() {
        return {
            menuLoaded: false,
        }
    },
    components: {
        AdminBackground,
        MobileMenu,
        Logo,
        Menu,
        MenuPoem,
        Hamburger,
        DarkLightSwitcher,
        Comments,
    },
    watch: {
        // async '$route'(to) {
        //     if (to.path.includes('versek') && !to.path.includes('admin')) {
        //         await this.getCategories();
        //     }
        //
        // }
    },
    methods: {
        redirect() {
            let token = this.$cookie.get('token');
            if (token === null || token === 'null') {
                if (this.$route.path.includes('/admin') && this.$route.name !== 'login') {
                    this.$router.push('/');
                }
            } else {
                if (this.$route.name === 'login') {
                    this.$router.push('/admin/versek');
                }
            }
        },
        async loadContent() {
            let result = await this.apiRequest('GET', '/content/all');
            if (result?.data) {
                this.$store.commit('setColors', result.data[0].colors);
                document.documentElement.style.setProperty('--poem_szellem', result.data[0].colors[0].colorLight);
                document.documentElement.style.setProperty('--poem_lelek', result.data[0].colors[1].colorLight);
                document.documentElement.style.setProperty('--poem_test', result.data[0].colors[2].colorLight);
                document.documentElement.style.setProperty('--poem_szellemlelek', result.data[0].colors[3].colorLight);
                document.documentElement.style.setProperty('--poem_szellemtest', result.data[0].colors[4].colorLight);
                document.documentElement.style.setProperty('--poem_lelektest', result.data[0].colors[5].colorLight);
                document.documentElement.style.setProperty('--poem_szellemlelektest', result.data[0].colors[6].colorLight);

                document.documentElement.style.setProperty('--admin_poem_szellem_light', result.data[0].colors[0].colorLight);
                document.documentElement.style.setProperty('--admin_poem_lelek_light', result.data[0].colors[1].colorLight);
                document.documentElement.style.setProperty('--admin_poem_test_light', result.data[0].colors[2].colorLight);
                document.documentElement.style.setProperty('--admin_poem_szellemlelek_light', result.data[0].colors[3].colorLight);
                document.documentElement.style.setProperty('--admin_poem_szellemtest_light', result.data[0].colors[4].colorLight);
                document.documentElement.style.setProperty('--admin_poem_lelektest_light', result.data[0].colors[5].colorLight);
                document.documentElement.style.setProperty('--admin_poem_szellemlelektest_light', result.data[0].colors[6].colorLight);

                document.documentElement.style.setProperty('--admin_poem_szellem_dark', result.data[0].colors[0].colorDark);
                document.documentElement.style.setProperty('--admin_poem_lelek_dark', result.data[0].colors[1].colorDark);
                document.documentElement.style.setProperty('--admin_poem_test_dark', result.data[0].colors[2].colorDark);
                document.documentElement.style.setProperty('--admin_poem_szellemlelek_dark', result.data[0].colors[3].colorDark);
                document.documentElement.style.setProperty('--admin_poem_szellemtest_dark', result.data[0].colors[4].colorDark);
                document.documentElement.style.setProperty('--admin_poem_lelektest_dark', result.data[0].colors[5].colorDark);
                document.documentElement.style.setProperty('--admin_poem_szellemlelektest_dark', result.data[0].colors[6].colorDark);
            }
        },
    },
    async mounted() {
        // TODO: add timing to mode
        await this.loadContent();
        this.redirect();
        this.$store.commit('setMode', 'dark')
        if (this.$route.path.includes('/admin')) {
            this.$store.commit('setMode', 'dark')
        }
        // this.$store.commit('setMode', 'dark')
        // let date = new Date();
        // if(date.getHours() >= 19 || date.getHours() < 6){
        //     this.$store.commit('setMode', 'dark')
        // }
    }
}
</script>


<style scoped>

@media (min-width: 0px) and (max-width: 1023px) {
    .menupoem{
        display: none;
    }

    .darklightdesktop{
        display: none;
    }
}

@media (min-width: 1024px){

    .menupoem{ display: block}
}
/*.gradient__bottom{
  position: fixed;
  width: 100vw;
  height: 300px;
  left: 0;
  bottom: 0;
  background: linear-gradient(0deg, var(--bg), transparent);
}*/
</style>
