import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '../views/HomeView.vue'
import PoemView from "@/views/PoemView";
import PoemOpened from "@/views/PoemOpened";
import TravellerView from "@/views/TravellerView";
import ContactView from "@/views/ContactView";

import AdminLoginView from "@/views/admin/AdminLoginView";
import AdminPoemView from "@/views/admin/AdminPoemView";
import AdminPoemNew from "@/views/admin/AdminPoemNew";
import AdminTravellerView from "@/views/admin/AdminTravellerView";
import AdminContactView from "@/views/admin/AdminContactView";
import AdminHomeView from "@/views/admin/AdminHomeView";
import AdminCommentsView from "@/views/admin/AdminCommentsView.vue";
import AdminStatistics from "@/views/admin/AdminStatistics.vue";
import AdminCategoryView from "@/views/admin/AdminCategoryView.vue";
import Welcome from "@/components/Welcome.vue";
import PageNotFound from "@/views/PageNotFound.vue";

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'welcome',
    component: Welcome
  },
  {
    path: '/fooldal',
    name: 'home',
    component: HomeView
  },
  {
    path: '/versek',
    name: 'poem',
    component: PoemView
  },
  {
    path: '/versek/:name',
    name: 'poemlist',
    component: PoemOpened
  },
  {
    path: '/vandor',
    name: 'traveller',
    component: TravellerView
  },
  {
    path: '/kapcsolat',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/admin',
    name: 'login',
    component: AdminLoginView
  },
  {
    path: '/admin/versek',
    name: 'admin_poem',
    component: AdminPoemView
  },
  {
    path: '/admin/versek/uj',
    name: 'admin_poem_new',
    component: AdminPoemNew
  },
  {
    path: '/admin/versek/szerkeszt/:name',
    name: 'admin_poem_edit',
    component: AdminPoemNew
  },
  {
    path: '/admin/vandor',
    name: 'admin_traveller',
    component: AdminTravellerView
  },
  {
    path: '/admin/kapcsolat',
    name: 'admin_contact',
    component: AdminContactView
  },
  {
    path: '/admin/fooldal',
    name: 'admin_home',
    component: AdminHomeView
  },
  {
    path: '/admin/kommentek',
    name: 'admin_comments',
    component: AdminCommentsView
  },
  {
    path: '/admin/statisztika',
    name: 'admin_stat',
    component: AdminStatistics
  },
  {
    path: '/admin/kategoriak',
    name: 'admin_category',
    component: AdminCategoryView
  },
  { path: "*", component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
