import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookie from 'vue-cookie'
import { BootstrapVue } from 'bootstrap-vue'
import Vue2TouchEvents from 'vue2-touch-events'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/animations.css'
import '@/assets/css/global.css'
import '@/assets/css/fonts.css'




Vue.use(VueAxios, axios)
Vue.config.productionTip = false;
Vue.use(BootstrapVue)
Vue.use(Vue2TouchEvents)
Vue.use(VueCookie);

Vue.use(VueGtag, {
  config: {
    id: 'G-PD0GRGX4HE',
    params: {
      send_page_view: false
    }
  }
}, router)

Vue.mixin({
  methods: {
    async apiRequest(method, route, data = null) {

      // let address = `http://localhost:3000${route}`
      let address = `https://api.vandornaplo.hu${route}`
      // let address = `http://192.168.1.79:3000${route}`
      let output;
      let headers = {
        'Authorization': this.$cookie.get('token'),
        "Access-Control-Allow-Origin": "*"
      };
      let parameters = {
        method: method,
        url: address,
        data: data,
        headers: headers,
      }
      await this.axios(parameters).then((response) => {
        output = response.data;
      }).catch((errors) => {
        console.log('errors', errors);
      });
      return output;
    },
    changeRoute(route, beforeChange = null) {

      if(route !== '/versek') this.$store.commit('setTempMenuPoem', []);
      if (this.$route.path !== route) {
        if(!route.includes('/versek') && !route.includes('/admin')){
          this.$store.commit('closeMenuPoem');
        }
        if(beforeChange) beforeChange();
        return this.$router.push(route)
      }
    }
  }
})

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
