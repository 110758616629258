<template>
  <div class="menu__mobile">
    <div v-if="!$route.path.includes('/admin')" class="mobilemenu">
                <DarkLightSwitcher/>
      <p @click="changeRoute('/versek', beforeChange)" :class="{ active: $route.path === '/versek' }">Napló</p>
      <p @click="changeRoute('/vandor', beforeChange)" :class="{ active: $route.path === '/vandor' }">Vándor</p>
      <p @click="changeRoute('/kapcsolat', beforeChange)" :class="{ active: $route.path === '/kapcsolat' }">Kapcsolat</p>

    </div>

    <div v-else>
      <p @click="changeRoute('/admin/versek')" :class="{ active: $route.path === '/admin/versek' }">Napló</p>
        <p @click="changeRoute('/admin/kommentek')" :class="{ active: $route.path === '/admin/kommentek' }">Kommentek</p>
      <p @click="changeRoute('/admin/fooldal')" :class="{ active: $route.path === '/admin/fooldal' }">Fõoldal</p>
      <p @click="changeRoute('/admin/vandor')" :class="{ active: $route.path === '/admin/vandor' }">Vándor</p>
      <p @click="changeRoute('/admin/kapcsolat')" :class="{ active: $route.path === '/admin/kapcsolat' }">Kapcsolat</p>
      <p @click="changeRoute('/admin/statisztika')" :class="{ active: $route.path === '/admin/statisztika' }">Statisztika</p>

    </div>
  </div>
</template>

<script>
import DarkLightSwitcher from "@/components/DarkLightSwitcher.vue";
export default {
  name: "MobileMenu",
    components:{
      DarkLightSwitcher
    },
  methods:{
     beforeChange(){
       this.$store.commit('toggleHamburger');
     }
  }
}
</script>

<style scoped>
@media screen and (min-width: 1200px) {
  .menu__mobile{
    display: none;
  }
}
@media screen and (max-width: 1199px) {
    .mobilemenu .light_switcher{
        margin-bottom: 50px;
        display: inline-block;
        vertical-align: middle;
    }
    .mobilemenu{
        height: 60vh;
        text-align: center;
        width: fit-content;
        height: fit-content;
        position: absolute;
        left: 0;
        right: 0;
        top: 0px;
        bottom: 0;
        margin: auto;
    }
  .menu__mobile{
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--bg);
    z-index: 5000;
      visibility: hidden;
  }

  .menu__mobile div{

  }

  p{
    cursor: pointer;
    font-family: El Messiri, serif;
    font-size: 25pt;
    color: var(--color);
    margin-bottom: 50px;
      transition: ease all 0.333s;
  }

}
</style>