<template>
    <div class="hamburger" @click="toggleHamburger">
        <div class="position-relative">
            <div class="hamburger__closed"></div>
            <div class="hamburger__closed"></div>
            <div class="hamburger__closed"></div>

            <div class="hamburger__opened left"></div>
            <div class="hamburger__opened right"></div>
        </div>


    </div>
</template>

<script>
import {gsap} from "gsap";

export default {
    name: "HamburgerComponent",
    computed: {
        hamburger: function () {
            return this.$store.getters.hamburger;
        },
    },

    watch: {
        hamburger(newValue) {
            let closed = document.querySelectorAll('.hamburger__closed')
            let opened = document.querySelectorAll('.hamburger__opened')
            let menu = document.querySelectorAll('.menu__mobile')
            if (newValue) {

                gsap.timeline()
                    .to(closed, {autoAlpha: 0, duration: 0.666, x: '0', stagger: 0.1})
                    .to(opened, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1}, '-=0.333')
                    .to(menu, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1}, '-=0.666')
            } else {
                gsap.timeline()
                    .to(opened, {autoAlpha: 0, duration: 0.666, stagger: 0.1})
                    .to(closed, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1}, '-=0.333')
                    .to(menu, {autoAlpha: 0, duration: 0.666, x: '0', stagger: 0.1}, '-=0.666')
            }
        }
    },
    methods: {
        toggleHamburger() {
            this.$store.commit('toggleHamburger');


        }
    },
    mounted() {
        let closed = document.querySelectorAll('.hamburger__closed')
        gsap.timeline().to(closed, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1}, )

        },
}
</script>

<style scoped>


@media (min-width: 0px) and (max-width: 1023px) {
    .hamburger {
        position: absolute;
        right: 30px;
        top: 39px;
        z-index: 5001;
    }

    .hamburger .hamburger__closed {
        width: 25px;
        height: 3px;
        margin-bottom: 4px;
        background-color: var(--color);
        visibility: hidden;
        transform: translateX(50px);
    }

    .hamburger .hamburger__opened {
        position: absolute;
        left: 0;
        top: 7px;
        width: 25px;
        height: 3px;
        margin-bottom: 4px;
        background-color: var(--color);
        visibility: hidden;
    }

    .hamburger .hamburger__opened.left {
        transform: rotate(45deg);
    }

    .hamburger .hamburger__opened.right {
        transform: rotate(-45deg);
    }
}

@media (min-width: 320px) {
}

@media (min-width: 481px) and (max-width: 1023px) {
}

@media (min-width: 641px) and (max-width: 1023px) {
}

@media (min-width: 801px) and (max-width: 1023px) {
}

@media (min-width: 1024px) {
    .hamburger {
        display: none;
    }
}

@media (min-width: 1281px) {

}
</style>