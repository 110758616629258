<template>
  <div class="contact">
<!--    TODO: load from backend-->

<!--    TODO: add links-->
    <div class="traveller__social">
        <p class="contact__text">{{text}}</p>
        <div class="traveller__social_svg">
            <svg class="traveller__icon-email" viewBox="0 0 66.9 66.9" @click="openSocial('email')">
                <path d="M33.45,0A33.45,33.45,0,1,0,66.9,33.45,33.49,33.49,0,0,0,33.45,0Zm0,64.9A31.45,31.45,0,1,1,64.9,33.45,31.49,31.49,0,0,1,33.45,64.9Z"/>
                <path d="M50.45,17.45h-34a3.08,3.08,0,0,0-3,3v26a3.08,3.08,0,0,0,3,3h34a3.08,3.08,0,0,0,3-3v-26a3.08,3.08,0,0,0-3-3Zm-.2,3-16.8,10.9L16.7,20.45Zm.2,26h-34V23.2l17,11.15,17-11.15Z"/>
            </svg>
            <svg class="traveller__icon-email-mobile" viewBox="0 0 66.9 66.9" @click="openSocial('emailmobile')">
                <path d="M33.45,0A33.45,33.45,0,1,0,66.9,33.45,33.49,33.49,0,0,0,33.45,0Zm0,64.9A31.45,31.45,0,1,1,64.9,33.45,31.49,31.49,0,0,1,33.45,64.9Z"/>
                <path d="M50.45,17.45h-34a3.08,3.08,0,0,0-3,3v26a3.08,3.08,0,0,0,3,3h34a3.08,3.08,0,0,0,3-3v-26a3.08,3.08,0,0,0-3-3Zm-.2,3-16.8,10.9L16.7,20.45Zm.2,26h-34V23.2l17,11.15,17-11.15Z"/>
            </svg>
<!--            <svg class="traveller__icon-fb" viewBox="0 0 66.9 66.9" @click="openSocial('facebook')">-->
<!--                <path d="M28.55,23.72v4.81H22.91v6.54h5.64V51.81h6.76V35.07h5.61l.85-6.54H35.31V24.36c0-1.92.51-3.17,3.23-3.17H42V15.35a49.69,49.69,0,0,0-5-.26C32,15.09,28.55,18.14,28.55,23.72Z"/>-->
<!--                <path d="M33.45,0A33.45,33.45,0,1,0,66.9,33.45,33.49,33.49,0,0,0,33.45,0Zm0,64.9A31.45,31.45,0,1,1,64.9,33.45,31.49,31.49,0,0,1,33.45,64.9Z"/>-->
<!--            </svg>-->
<!--            <svg class="traveller__icon-insta" viewBox="0 0 66.9 66.9" @click="openSocial('instagram')">-->
<!--                <path d="M43.45,21.21a2.25,2.25,0,1,0,2.25,2.24A2.24,2.24,0,0,0,43.45,21.21Z"/>-->
<!--                <path d="M33.45,23.83a9.62,9.62,0,1,0,9.62,9.62A9.62,9.62,0,0,0,33.45,23.83Zm0,15.86a6.24,6.24,0,1,1,6.24-6.24A6.24,6.24,0,0,1,33.45,39.69Z"/>-->
<!--                <path d="M41.18,14.84c-2-.1-2.64-.12-7.73-.12s-5.72,0-7.72.11c-6.8.32-10.58,4.09-10.89,10.9-.1,2-.12,2.63-.12,7.72s0,5.73.11,7.72c.32,6.81,4.09,10.59,10.9,10.9,2,.09,2.63.11,7.72.11s5.73,0,7.72-.11c6.8-.31,10.59-4.09,10.9-10.9.09-2,.11-2.63.11-7.72s0-5.72-.11-7.72C51.76,18.93,48,15.15,41.18,14.84ZM48.7,41c-.23,5-2.6,7.45-7.68,7.68-2,.09-2.56.11-7.57.11s-5.59,0-7.57-.11c-5.09-.23-7.44-2.65-7.67-7.68-.1-2-.11-2.57-.11-7.57s0-5.59.11-7.57c.23-5,2.59-7.44,7.67-7.67,2-.09,2.57-.11,7.57-.11s5.6,0,7.57.11c5.08.23,7.45,2.64,7.68,7.67.09,2,.11,2.57.11,7.57S48.79,39.05,48.7,41Z"/><path class="cls-1" d="M33.45,0A33.45,33.45,0,1,0,66.9,33.45,33.49,33.49,0,0,0,33.45,0Zm0,64.9A31.45,31.45,0,1,1,64.9,33.45,31.49,31.49,0,0,1,33.45,64.9Z"/>-->
<!--            </svg>-->
        </div>
        <p class="contact__email">Vágólapra másolva!</p>

    </div>




   
  </div>
</template>

<script>
import {gsap} from "gsap";

export default {
  name: "ContactView",
    data() {
        return {
            text: null,

        }
    },
    methods:{
        async loadContent() {
            let result = await this.apiRequest('GET', '/content/all');
            if (result?.data) {
                this.text = result.data[0].contact;
            }
        },
        openSocial(social){
            if(social === 'email'){
                navigator.clipboard.writeText('vandor@vandornaplo.hu');
                let text = document.querySelector('.contact .contact__email');
                gsap.timeline()
                    .to(text, {autoAlpha: 1, duration: 0.333, stagger: 0.1})
                let timer = setTimeout(() => {
                    gsap.timeline()
                        .to(text, {autoAlpha: 0, duration: 0.333, stagger: 0.1})
                    clearTimeout(timer);
                }, 3000);
                // window.open("mailto:vandor@vandornaplo.hu", "_blank");
            }
            if(social === 'emailmobile'){
                // navigator.clipboard.writeText('vandor@vandornaplo.hu');
                // let text = document.querySelector('.contact .contact__email');
                // gsap.timeline()
                //     .to(text, {autoAlpha: 1, duration: 0.333, stagger: 0.1})
                // let timer = setTimeout(() => {
                //     gsap.timeline()
                //         .to(text, {autoAlpha: 0, duration: 0.333, stagger: 0.1})
                //     clearTimeout(timer);
                // }, 3000);
                window.open("mailto:vandor@vandornaplo.hu", "_blank");
            }
            if(social === 'facebook'){
                window.open("https://www.facebook.com/tamas.k.darai", "_blank");
            }
            if(social === 'instagram'){
                window.open("https://www.instagram.com/tamas.k.darai/", "_blank");
            }
        }
    },
    async mounted() {
        await this.loadContent();
        let text = document.querySelector('.contact .contact__text');
        let social = document.querySelectorAll('.traveller__social svg');
        gsap.timeline()
            .to(text, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, )
            .to(social, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, '-=0.333');
    },
    destroyed() {
        let text = document.querySelector('.contact .contact__text');
        let social = document.querySelectorAll('.traveller__social svg');
        gsap.timeline()
            .to(text, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, )
            .to(social, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, '-=0.333')


    }
}
</script>

<style scoped>



.traveller__icon-insta {
  //left: 172px;
  //top: 0px;
}

.traveller__icon-fb{

  //left: 86px;
  //top: 0px;

}
.traveller__icon-email{
  //position: absolute;
  //left: 0px;
  //top: 0px;
}

svg{
  //position: absolute;
  width: 67px;
  height: 67px;
  cursor: pointer;
    visibility: hidden;
    transform: translateX(200px);
}

svg:hover path{
    fill: var(--color);
    opacity: 1;
}

svg path{
  fill: var(--grey);
    opacity: 0.5;
    transition: 0.666s ease all;
}
.contact__text {
//left: 567px;
//top: 327px;
//position: absolute;
    overflow: visible;
    width: 45vw;

    line-height: 5vh;
//margin-top: -4px;
    text-align: center;
    font-family: El Messiri;
    font-style: normal;
    font-weight: normal;
    color: var(--color);
    visibility: hidden;
    transform: translateX(200px);


}

.traveller__social_svg{
    display: flex;
    justify-content: center;
}

@media (min-width:0px) and (max-width:1023px) {
    .traveller__icon-email{
        display: none;
    }

    .traveller__icon-email-mobile{
        display: block;
    }
    .contact__email{
        left: 0px;
        right: 0;
        margin: auto;
        bottom: 10vh;
        position: absolute;
        overflow: visible;
        //width: 788px;
        //height: 120px;
        //line-height: 36px;
        //margin-top: -4px;
        text-align: center;
        font-family: El Messiri;
        font-style: normal;
        font-weight: normal;
        font-size: 6vw;
        color: var(--grey);
        visibility: hidden;
    }

    .traveller__social{
        top: 20vh;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
    }
    .contact__text {
        left: 0;
        right: 0;
        margin: auto;
        //position: absolute;
        line-height: 5vh;
        font-family: El Messiri,serif;
        font-size: 6vw;
        width: 80vw;
        color: var(--color);
        transform: translateX(200px);
        visibility: hidden;
        top: 0;
    }

    .traveller__social_svg{
        margin-top: 50px;
        //left: 0;
        //right: 0;
        //margin: auto;
        //position: absolute;
        //top: 40%;
        //bottom: 40vh;
    }

    .traveller__social_svg svg:nth-child(1), .traveller__social_svg svg:nth-child(2), .traveller__social_svg svg:nth-child(3){
        margin-right: 20px;
    }
}
@media (min-width:320px)  {
}

@media (min-width:481px) and (max-width:1023px) {
}
@media (min-width:641px) and (max-width:1023px) {
}

@media (min-width:801px) and (max-width:1023px)  {
}


@media (min-width:1024px) and (max-width:1365px)  {


    .contact__text{
        font-size: 2.3vw;
        height: 120px;
    }

    .traveller__social_svg{
        margin-top: 150px;
    }
}
@media (min-width:1366px)  {
    .traveller__social_svg{
        margin-top: 150px;
    }

    .contact__text{
        font-size: 2.3vw;
    }
}

@media (min-width: 1536px) {
    .traveller__social_svg{
        margin-top: 120px;
    }

    .contact__text{
        font-size: 1.7vw;
    }

    .contact__email{
        font-size: 1.4vw;
        margin-top: 120px;
    }
}
@media (min-width:1024px) {
    .traveller__icon-email{
        display: block;
    }


    .contact__email{
        overflow: visible;
        text-align: center;
        font-family: El Messiri;
        font-style: normal;
        font-weight: normal;
        color: var(--grey);
        visibility: hidden;
    }


    .traveller__social {
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }



    .traveller__social_svg svg{
        margin-right: 20px;
    }
}

@media (min-width:1024px) and (max-width:1365px) {
    .traveller__icon-email{
        display: none;
    }

    .traveller__icon-email-mobile{
        display: block;
    }
}
@media (min-width:1366px) {
    .traveller__icon-email-mobile{
        display: none;
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .contact__email{
        overflow: visible;
        text-align: center;
        font-family: El Messiri;
        font-style: normal;
        font-weight: normal;
        color: var(--grey);
        visibility: hidden;
        font-size: 1.7rem;
        margin-top: 50px;
    }
}

</style>