<template>
    <div id="admin-poem">
        <div @click="reverseBlocks" class="poem__date-filter">
            <svg class="poem__btn-filter" viewBox="0 0 26.13 22.43">
                <path class="cls-1"
                      d="M12.56,22.14.08.88A.58.58,0,0,1,.58,0h25a.58.58,0,0,1,.5.88L13.57,22.14A.58.58,0,0,1,12.56,22.14Z"/>
                <path class="cls-2"
                      d="M13.07,19.05,22.56,2.88a.58.58,0,0,0-.5-.88h-18a.59.59,0,0,0-.51.88l9.5,16.17m-.51,3.09L.08.88A.58.58,0,0,1,.58,0h25a.58.58,0,0,1,.5.88L13.57,22.14A.58.58,0,0,1,12.56,22.14Z"/>
            </svg>
            <!--    TODO: add logic here-->
            <p class="poem__filter">
                Szûrés idõrend szerint
            </p>
        </div>
        <div class="poem__list">


            <b-row v-for="(block, i) in blocks" v-bind:key="i">
                <b-col class="poem__list-date" cols="3">
                    <p>{{ block.year }}</p>
                </b-col>
                <b-col class="poem__list-titles">
                    <p
                            v-for="(poem, j) in block.poems"
                            v-bind:key="j"
                            @click="openRow(poem.url)"
                            :class="{active: poem.url === active}"
                    >
                        <span>{{ poem.title }}</span>

                    </p>
                </b-col>
            </b-row>
        </div>

    </div>
</template>

<script>
import {gsap} from "gsap";

export default {
    name: "AdminPoemView",
    data() {
        return {
            active: null,
            blocks: [],
            isReversed: false,
            activeName: null,
            poem: null,
        }
    },
    methods: {
        async openRow(url) {
            if (this.active !== url) {
                this.active = url;
                await this.loadPoem(url);
                this.openComments();
            } else {
                this.active = null;
                let comments = document.querySelector('.comments');
                gsap.timeline()
                    .to(comments, {autoAlpha: 0, x: 100, duration: 0.666, stagger: 0.1})
            }

        },
        openComments() {
            this.$store.commit('setComments', this.poem);
            let comments = document.querySelector('.comments');
            gsap.timeline()
                .to(comments, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1})
        },
        async loadPoem(url) {
            let result = await this.apiRequest('GET', `/poem/${url}`);
            if (result?.data) {
                this.poem = result.data
            }
        },
        async loadPoems() {
            let result = await this.apiRequest('GET', '/poem/all');
            if (result?.data) {
                this.blocks = result.data
            }
        },
        reverseArrays() {
            let filter = this.blocks;
            filter.forEach(e => {
                let poems = e.poems;
                e.poems = poems.reverse();
            })

            this.blocks = filter.reverse();
        },
        reverseBlocks() {

            this.isReversed = !this.isReversed;
            //
            let btn = document.querySelector('.poem__btn-filter');
            let poems = document.querySelectorAll('.poem__list .row');
            if (this.isReversed) {
                gsap.timeline()
                    .to(btn, {rotation: 0, duration: 0.333, stagger: 0.1})
                    .to(poems, {
                        autoAlpha: 0, duration: 0.666, x: '200px', stagger: 0.1, onComplete: () => {
                            this.reverseArrays();
                        }
                    }, '-=0.333')
                    .to(poems, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1},)
            } else {
                gsap.timeline()
                    .to(btn, {rotation: 180, duration: 0.666, stagger: 0.1})
                    .to(poems, {
                        autoAlpha: 0, duration: 0.666, x: '200px', stagger: 0.1, onComplete: () => {
                            this.reverseArrays();
                        }
                    }, '-=0.333')
                    .to(poems, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1},)

            }
        },
    },
    async mounted() {
        await this.loadPoems();
        let btn = document.querySelector('.poem__btn-filter');
        gsap.timeline()
            // .to(this.elements.poems, {autoAlpha: 0, duration: 0.005, x: '100px', stagger: 0.1})
            .to(btn, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1})

    }
}
</script>

<style scoped>
.d-block p {
    font-family: El Messiri;
    font-size: 25px;
}

.d-block p span {
    font-weight: bold;
}

#admin-poem .row:not(:last-child) {
    margin-bottom: 55px;
}

.poem__filter {

//position: absolute; font-family: El Messiri;
    font-size: 20px;
    color: white;
    margin-top: -5px;
    margin-left: 20px;

}

.poem__btn-filter {
//position: absolute; width: 27px;
    height: 23px;
    /*transform: matrix(1,0,0,1,412,128) rotate(180deg);*/
    rotate: 180deg;
    transform-origin: center;
    left: 412px;
    top: 100px;
    /*top: 10px;*/
}

.poem__btn-filter .cls-1 {
    fill: none;
}

.poem__btn-filter .cls-2 {
    fill: #575756;
}

.poem__date-filter {
    cursor: pointer;
    display: flex;
    left: 412px;
    top: 45px;
    position: absolute;
}

.poem__list-edit, .poem__list-delete, .poem__list-comments, .poem__list-delete_no {
    font-family: El Messiri;
    font-size: 15px;
    color: rgba(130, 48, 51, 1);
    letter-spacing: 0.2px;
    position: absolute;
    margin-top: 12px;
    cursor: pointer;
}

.poem__list-edit:hover, .poem__list-delete:hover {
    color: rgb(202, 48, 51);
}

.poem__list-delete {
    right: 85px;
    /*top: 11px;*/
}

.poem__list-delete_yes {
    right: 50px;
    /*top: 11px;*/
}

.poem__list-delete_no {
    right: 15px;
    /*top: 11px;*/
}

.poem__list-comments {

    right: 153px;
    /*top: 11px;*/
}

.poem__list {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 80vh;
    position: fixed;
    left: 405px;
    top: 120px;
    width: 900px;
}

.poem__list-date p {
    font-family: El Messiri, serif;
    font-size: 20px;
    color: var(--color_secondary);
    padding-top: 9px;
    padding-left: 10px;
}

.poem__list-titles p {
    font-family: El Messiri, serif;
    font-size: 28px;
    color: var(--color);
    margin-bottom: -3px;
    cursor: pointer;
}

.poem__list-titles {
    padding-left: 5px;
    position: relative;
}

.active {
    color: var(--color_secondary) !important;
}

.admin__poem-new-icon {
    width: 31.719px;
    height: 31.719px;

}

.admin__poem-new-icon .cls-2 {
    fill: #823033;
}

.admin__poem-new-block {
    cursor: pointer;
    left: 1360px;
    top: 40px;
    position: absolute;
    display: flex;
}

.admin__poem-new {
//left: 1385px; //top: 73px; //position: absolute; overflow: visible;
    width: 210px;
    white-space: nowrap;
    line-height: 77px;
    margin-top: -24px;
    text-align: center;
    font-family: El Messiri;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
    letter-spacing: 2.41px;
}


</style>