<template>
    <div class="admin__home">
        <svg class="admin__poem-new-arrow" viewBox="7496 5161 16.453 13.358" @click="changeRoute('/admin/versek')">
            <path id="Path_3" d="M 7496 5161 L 7504.3740234375 5174.3583984375 L 7512.453125 5161">
            </path>
        </svg>
        <p class="admin__contact-title">Vándor szerkesztése</p>
        <b-form-textarea
                class="admin__contact-edit-text"
                v-model="form.about"
                placeholder="Kapcsolat szöveg"
                rows="3"
                max-rows="6">
        </b-form-textarea>

        <p class="admin__poem-new-create" @click="editContact" :class="{create_disabled: !this.fieldsFilled}">
            <span v-if="!isCreated">Szerkesztés</span>
            <span v-else class="admin__poem-new-created">Szerkesztve!</span>
        </p>


        <div id="preview">
            <div v-if="form.aboutImage">
                <img v-if="form.aboutImage" :src="form.aboutImage" />
                <!--              <svg viewBox="0 0 14.47 14.47" v-if="form.image" @click="removeFile">-->
                <!--                  <polygon class="cls-1" points="14.47 1.11 13.36 0 7.23 6.12 1.11 0 0 1.11 6.12 7.24 0 13.36 1.11 14.47 7.24 8.35 13.36 14.47 14.47 13.36 8.35 7.24 14.47 1.11"/>-->
                <!--              </svg>-->

            </div>
            <div v-else-if="url">
                <img v-if="url" :src="url" />
                <!--              <svg viewBox="0 0 14.47 14.47" v-if="url" @click="removeFile">-->
                <!--                  <polygon class="cls-1" points="14.47 1.11 13.36 0 7.23 6.12 1.11 0 0 1.11 6.12 7.24 0 13.36 1.11 14.47 7.24 8.35 13.36 14.47 14.47 13.36 8.35 7.24 14.47 1.11"/>-->
                <!--              </svg>-->
            </div>


        </div>

        <div class="image-upload" :class="{rotate: url !== null || form.aboutImage !== null}" @click="removeFile">
            <label for="file-input">
                <svg class="admin__poem-form-img" viewBox="0 0 48 48">
                    <polygon class="cls-2" points="24.59 22.95 24.59 7.8 22.59 7.8 22.59 22.95 7.45 22.95 7.45 24.95 22.59 24.95 22.59 40.09 24.59 40.09 24.59 24.95 39.74 24.95 39.74 22.95 24.59 22.95"/>
                    <path class="cls-2" d="M41,7A24,24,0,0,0,7.24,6.84L7,7l-.17.19a24,24,0,0,0,0,33.56L7,41a1.22,1.22,0,0,0,.17.15A24,24,0,0,0,41,7ZM39.56,39.56a22,22,0,1,1,0-31.12A22,22,0,0,1,39.56,39.56Z"/>
                    <path class="cls-2" d="M7.2,41.12A24,24,0,0,0,41,41a24,24,0,0,1-33.77.15Z"/>
                </svg>
            </label>
            <input id="file-input" type="file" @change="onFileChange" />
        </div>
    </div>
  <!--  TODO: add button-->
</template>

<script>
export default {
    name: "AdminTravellerView",
    data() {
        return {
            form: {
                _id: null,
                about: null,
                aboutImage: null,
            },
            isCreated: false,
            url: null,
            base64: null,
        }
    },
    computed: {
        // a computed getter
        fieldsFilled() {
            return this.form.about !== null || this.form?.about?.length > 0;
        }
    },
    methods:{
        async editContact(){
            if(this.base64 !== null) this.form.aboutImage = this.base64;
            let result = await this.apiRequest('PUT', `/content/edit/${this.form._id}`, this.form);
            // this.url = null;
            // this.base64 = null;
            if(result?.status){
                this.isCreated = true;
                let timer = setTimeout(() => {
                    this.isCreated = false;
                    clearTimeout(timer);
                }, 3000);

            }
        },
        removeFile(){
            this.url = null;
            this.base64 = null;
            this.form.aboutImage = null;
        },
      async getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      },



      async onFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          try {





            const base64String = await this.getBase64(file);
            this.base64 = await this.convertToWebP(base64String);

            this.url = URL.createObjectURL(file);
          } catch (error) {
            console.error('Error converting image:', error);
          }
        }
      },

      convertToWebP(base64String) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {

            // Calculate new dimensions while maintaining aspect ratio
            let newWidth = img.width;
            let newHeight = img.height;
            if (newWidth > 1920) {

              const aspectRatio = img.height / img.width;
              newWidth = 1920;
              newHeight = Math.round(newWidth * aspectRatio);
            }

            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, newWidth, newHeight); // Draw the resized image onto the canvas
            canvas.toBlob(blob => {
              // Set maximum target file size (100KB)
              console.log('blob', blob)
              const maxSizeInBytes = 250 * 1024;

              // Quality adjustment loop
              let quality = 1;
              let done = false;
              let webPString = canvas.toDataURL('image/webp', quality);
              let fileSizeInBytes = Math.round((webPString.length * 3) / 4);
              while (!done && quality > 0 && fileSizeInBytes > maxSizeInBytes) {
                webPString = canvas.toDataURL('image/webp', quality);
                fileSizeInBytes = Math.round((webPString.length * 3) / 4); // Base64 string length estimation
                if (fileSizeInBytes <= maxSizeInBytes || quality <= 0) {

                  done = true;
                  const base64Data = webPString.split(',')[1]; // Get the Base64 data after the comma
                  resolve(`data:image/webp;base64,${base64Data}`);
                } else {

                  quality -= 0.01;
                }
              }
            }, 'image/webp');
          };
          img.onerror = error => reject(error);
          img.src = base64String;
        });
      },
        async loadContent(){
            let result = await this.apiRequest('GET', '/content/all');
            if(result?.data){
                this.form.about = result.data[0].about;
                this.form._id = result.data[0]._id;
                this.form.aboutImage = result.data[0].aboutImage;
            }
        },
    },
    async mounted() {
        await this.loadContent();
    }
    // TODO: load text
    // TODO: connect to api
}
</script>

<style scoped>
.admin__poem-form-img{
    cursor: pointer;
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .admin__poem-new-arrow {
        left: 380px !important;
    }

    .admin__contact-title, .admin__contact-edit-text {
        left: 442px !important;
    }

    .admin__contact-edit-text {
        width: 820px !important;
    }

    #preview img {
        max-height: 70px !important;
        max-width: 70px !important;
    }

    #preview {
        left: 442px !important;
        bottom: 28px !important;
    }

    .image-upload{
        left: 522px !important;
        bottom: 40px !important;
    }

    .admin__poem-new-create{
        left: 1135px !important;
        bottom: initial !important;
        top: 695px !important;
    }


}

@media (min-width: 1441px) {
    #preview svg{
        position: absolute;
        right: -8px;
        width: 15px;
        top: -8px;
        cursor: pointer;
    }
    #preview {
        position: absolute;
        left: 1370px;
        bottom: 60px !important;
    }

    #preview img {
        max-height: 100px;
        max-width: 100px;
    }

    .image-upload{
        position: absolute;
        left: 1520px;
        bottom: 60px!important;
    }

    .admin__poem-new-create{
        bottom: 50px !important;
    }

}
.rotate{
    transform: rotate(45deg);
}
.admin__poem-form-img{
    width: 48px;
    height: 48px;
}
.admin__poem-form-img .cls-2, #preview svg polygon{
    fill:#823033;
}

.admin__home{
    position: absolute;
    width: 100vw;
    height: 100vh;
}
.image-upload{
    position: absolute;
    left: 1520px;
    bottom: 230px;
}

.image-upload>input {
    display: none;
}

#preview svg{
    position: absolute;
    right: -8px;
    width: 15px;
    top: -8px;
    cursor: pointer;
}
#preview {
    position: absolute;
    left: 1370px;
    bottom: 230px;
}

#preview img {
    max-height: 100px;
    max-width: 100px;
}
.admin__poem-new-created{
    color: #823033 !important;
}

.admin__poem-new-create{
    font-family: El Messiri;
    font-size: 20px;
    color: var(--color);
    letter-spacing: 2.41px;
    cursor: pointer;
    transition: ease all 0.4s;
    position: absolute;
    left: 1720px;
    bottom: 225px;
}

.admin__poem-new-arrow {
    overflow: visible;
    position: absolute;
    width: 16.453px;
    height: 13.358px;
    /*transform: matrix(1,0,0,1,479.0942,130.0503) rotate(90deg);*/
    transform-origin: center;
    left: 480px;
    top: 100px;
    rotate: 90deg;
    cursor: pointer;
}

.admin__poem-new-arrow path{
    fill: transparent;
    stroke: rgba(130,48,51,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

#Path_3 {

}

.admin__contact-title {
    left: 542px;
    top: 88px;
    position: absolute;


    line-height: 36px;
    text-align: center;
    font-family: El Messiri;
    font-size: 28px;
    color: var(--color);
}

.admin__contact-edit-text{
    width: 1020px;
    position: absolute;
    left: 542px;
    top: 180px;
    height: 60vh !important;
}

</style>