<template>
    <div id="poem__opened">
        <div class="poem__opened-navigation">
            <svg class="Path_2" viewBox="7496 5161 16.453 13.358" @click="back">
                <path id="Path_2" d="M 7496 5161 L 7504.3740234375 5174.3583984375 L 7512.453125 5161">
                </path>
            </svg>
            <div class="poem__opened-next" @click="next">
                <p>Következõ</p>
                <svg class="Path_3" viewBox="7496 5161 16.453 13.358">
                    <path id="Path_3" d="M 7496 5161 L 7504.3740234375 5174.3583984375 L 7512.453125 5161">
                    </path>
                </svg>
            </div>
        </div>

        <div class="poem__opened-block">
            <p class="poem__opened-title">{{ poem.title }}</p>

            <div class="poem__opened-text">
                <div class="poem__opened-content" v-html="poem.text">
                </div>
                <p class="poem__opened-data-mobile">{{ getDate(poem.date) }} {{ poem.otherInformations }}</p>

                <p class="poem__opened-data-other">
                    <span class="poem__opened-data_comments" @click="openComments">Hozzászólások ({{getComments() }})</span>

                                <span>

                      <svg class="poem__opened-data_social" @click="sharePoem('facebook')" viewBox="0 0 27.42 27.42">
                          <g>
                              <path class="cls-1"
                                    d="M11.72,9.76v2H9.43v2.65h2.29v6.79h2.75v-6.8h2.28l.34-2.65H14.47V10c0-.78.21-1.29,1.31-1.29h1.41V6.36a19.85,19.85,0,0,0-2-.1,3.21,3.21,0,0,0-3.44,3A2.44,2.44,0,0,0,11.72,9.76Z"/><path
                                  class="cls-1"
                                  d="M13.71,0h0a13.71,13.71,0,0,0,0,27.42h0A13.71,13.71,0,0,0,13.71,0ZM26.36,13.71A12.65,12.65,0,1,1,13.71,1.06,12.66,12.66,0,0,1,26.36,13.71Z"/></g>
                      </svg>
                    </span>
                </p>

                <img class="poem__opened-data-mobile-img" :alt="`vándornapló${poem.title}`" :src="poem.image"/>


            </div>


        </div>


        <!--    TODO: add comments-->
        <!--TODO: add copy link and facebook share-->

        <p class="poem__opened-data">
            {{ getDate(poem.date) }}, {{ poem.otherInformations }}
            <br/>
            <span class="poem__opened-data_comments" @click="openComments">Hozzászólások ({{ getComments() }})</span>
            <span>

          <svg class="poem__opened-data_social sociallink" @click="sharePoem('link')" viewBox="0 0 27.42 27.42">
              <g>
              <path class="cls-1"
                    d="M13.71,0h0A13.71,13.71,0,0,0,4,23.41a13.61,13.61,0,0,0,9.69,4h0A13.71,13.71,0,0,0,13.71,0Zm0,26.36A12.65,12.65,0,1,1,26.36,13.71,12.66,12.66,0,0,1,13.71,26.36Z"/><path
                      id="Path_57" data-name="Path 57" class="cls-1"
                      d="M16.73,16.34a1.87,1.87,0,0,0-1.25.49l-4.55-2.65a3.06,3.06,0,0,0,.06-.45,3.11,3.11,0,0,0-.06-.44l4.5-2.6a1.89,1.89,0,0,0,1.3.52,1.92,1.92,0,1,0-1.92-1.92h0a3.47,3.47,0,0,0,.06.45l-4.5,2.6a1.92,1.92,0,1,0,0,2.8l4.55,2.65a1.84,1.84,0,0,0-.06.41,1.87,1.87,0,1,0,1.87-1.87Z"/></g>
          </svg>
        </span>

            <span>

          <svg class="poem__opened-data_social" @click="sharePoem('facebook')" viewBox="0 0 27.42 27.42">
              <g>
                  <path class="cls-1"
                        d="M11.72,9.76v2H9.43v2.65h2.29v6.79h2.75v-6.8h2.28l.34-2.65H14.47V10c0-.78.21-1.29,1.31-1.29h1.41V6.36a19.85,19.85,0,0,0-2-.1,3.21,3.21,0,0,0-3.44,3A2.44,2.44,0,0,0,11.72,9.76Z"/><path
                      class="cls-1"
                      d="M13.71,0h0a13.71,13.71,0,0,0,0,27.42h0A13.71,13.71,0,0,0,13.71,0ZM26.36,13.71A12.65,12.65,0,1,1,13.71,1.06,12.66,12.66,0,0,1,26.36,13.71Z"/></g>
          </svg>
        </span>
            <transition name="fade" mode="out-in">
                <span v-if="isCopied" class="poem__opened-data_copy">
                    Vágólapra másolva!
                </span>
            </transition>
        </p>
        <!--    <p  class="poem__opened-share">-->
        <!--        -->
        <!--    </p>-->


        <div class="poem__scroll" :class="{scroll_disabled: !scrollEnabled, scroll_opened: imageOpened}">
            <ScrollDown :key="keyValue"/>
        </div>

        <div class="poem__opened-button"
             @click="toggleImage">
            <svg class="Ellipse_1 poem__opened-button_svg">
                <ellipse id="Ellipse_1" rx="24" ry="24" cx="24" cy="24">
                </ellipse>
            </svg>
            <svg class="Ellipse_2 poem__opened-button_svg">
                <ellipse id="Ellipse_2" rx="5" ry="5" cx="5" cy="5">
                </ellipse>
            </svg>
        </div>

        <svg @click="toggleImage" class="poem__opened-button-opened" viewBox="0 0 31.72 31.72">
            <path class="cls-2 poem__opened-button-opened_cls"
                  d="M31.71,15.79a14.46,14.46,0,0,0-.07-1.47c0-.39-.1-.79-.17-1.18,0-.11,0-.21,0-.32-.09-.47-.2-.93-.33-1.39l0-.09A14.1,14.1,0,0,0,30.58,10l0-.07c-.17-.43-.37-.85-.58-1.26a1.11,1.11,0,0,1-.07-.16c-.2-.37-.43-.74-.66-1.1,0-.08-.09-.17-.15-.25-.22-.34-.46-.66-.71-1-.08-.1-.15-.21-.23-.3-.32-.39-.66-.76-1-1.12a.46.46,0,0,0-.07-.07,15.85,15.85,0,1,0,0,22.42l.05-.05c.37-.37.71-.75,1-1.14l.22-.3c.25-.32.5-.64.72-1,.06-.08.1-.17.16-.25.23-.36.45-.73.65-1.1,0-.06.05-.11.07-.17.21-.41.41-.82.58-1.25a.26.26,0,0,0,0-.08,13.92,13.92,0,0,0,.48-1.37l0-.1c.13-.45.24-.92.33-1.38a1.88,1.88,0,0,0,0-.33,11.56,11.56,0,0,0,.17-1.18c.05-.48.07-1,.08-1.47C31.72,15.87,31.71,15.83,31.71,15.79Zm-6.05,9.87a13.86,13.86,0,1,1,4.06-9.8A13.88,13.88,0,0,1,25.66,25.66Z"/>
            <polygon class="cls-2 poem__opened-button-opened_cls"
                     points="16.59 5.15 14.59 5.15 14.59 14.82 4.92 14.82 4.92 16.82 14.59 16.82 14.59 26.49 16.59 26.49 16.59 16.82 26.26 16.82 26.26 14.82 16.59 14.82 16.59 5.15"/>
        </svg>

        <img class="poemimage" :alt="`vándornapló${poem.title}`" :src="poem.image"/>

    </div>
</template>

<script>
import {gsap} from "gsap";
import ScrollDown from "@/components/ScrollDown.vue";

export default {
    name: "PoemOpened",
    components: {ScrollDown},
    data() {
        return {
            imageOpened: false,
            isCopied: false,
            poem: {
                title: null,
                text: null,
                date: null,
                otherInformations: null,
                image: null,
                categories: null,
                comments: null,
                url: null,
            },
            elements: {
                button: null,
                buttonDot: null,
                buttonOpened: null,
                buttonOpenedX: null,
                img: null,
                data: null,
                title: null,
                text: null,
                share: null,
                comments: null,
                navigation: null,

            },
            scrollEnabled: true,
            keyValue: 0,

        }
    },
    computed: {},
    methods: {
        getComments() {
            let count = 0;
            if (this.poem?.comments?.length > 0) {
                this.poem.comments.forEach(comment => {
                    count++;
                    if (comment.subComments) {
                        comment.subComments.forEach(sub => {
                            console.log('sub', sub)
                            count++;
                        })
                    }
                })
            }
            return count;
        },
        getDate(date) {
            let d = new Date(date);
            // let val;
            // let month;
            // if(d.getMonth() === '')
            let month = d.getMonth();
            if (month < 10) month = `0${month + 1}`
            let day = d.getDate();
            if (day < 10) day = `0${day}`
            return `${d.getFullYear()}. ${month}. ${day}.`;

        },
        back() {

            const event = new CustomEvent("poemback", {});
            document.dispatchEvent(event);
            this.$router.push('/versek');
        },
        async loadPoem() {
            let id = this.$route.path.replace("/versek/", "");
            let result = await this.apiRequest('GET', `/poem/${id}`);
            if (result?.data) {
                this.poem = result.data


                const event = new CustomEvent("poemLoaded", {
                    detail: {
                        data: this.poem
                    }
                });
                document.dispatchEvent(event);
            }
        },
        async next() {
            if (this.imageOpened) this.toggleImage();
            await this.$router.push(`/versek/${this.poem.nextUrl}`)
            let id = this.$route.path.replace("/versek/", "");
            let result = await this.apiRequest('GET', `/poem/${id}`);
            if (result?.data) {
                this.keyValue++;
                gsap.timeline()
                    .to(this.elements.navigation, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1})
                    .to(this.elements.title, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, '-=0.333')
                    .to(this.elements.text, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, '-=0.333')
                    .to(this.elements.button, {
                        autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1, onComplete: () => {
                            this.poem = result.data
                        }
                    }, '-=0.333')
                    .to(this.elements.navigation, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1})
                    .to(this.elements.title, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, '-=0.333')
                    .to(this.elements.text, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, '-=0.333')
                    .to(this.elements.button, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, '-=0.333')
            }
        },
        toggleImage() {
            this.imageOpened = !this.imageOpened;
            if (this.imageOpened) {
                gsap.timeline()
                    .to(this.elements.button, {autoAlpha: 0, duration: 0.1, stagger: 0.1})
                    .to(this.elements.buttonDot, {autoAlpha: 0, duration: 0.1, stagger: 0.1}, '-=0.1')
                    .to(this.elements.img, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, '-=0.1')
                    .to(this.elements.buttonOpened, {autoAlpha: 1, duration: 0.3, stagger: 0.1}, '-=0.1')
                    .to(this.elements.buttonOpenedX, {autoAlpha: 1, duration: 0.3, stagger: 0.1},)
                    .to(this.elements.data, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, '-=0.666')
                    .to(this.elements.share, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, '-=0.666')
            } else {
                gsap.timeline()
                    .to(this.elements.buttonOpened, {autoAlpha: 0, duration: 0.1, stagger: 0.1})
                    .to(this.elements.img, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, '-=0.1')
                    .to(this.elements.data, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, '-=0.666')
                    .to(this.elements.share, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, '-=0.666')
                    .to(this.elements.button, {autoAlpha: 1, duration: 0.3, stagger: 0.1}, '-=0.1')
                    .to(this.elements.buttonDot, {autoAlpha: 1, duration: 0.3, stagger: 0.1},)

            }

        },
        openComments() {
            this.$store.commit('setComments', this.poem);
            const event = new CustomEvent("commentOpened", {
                detail: {
                    data: this.poem
                }
            });
            document.dispatchEvent(event);

            gsap.timeline()
                .to(this.elements.comments, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1})
        },
        sharePoem(share) {
            if (share === 'link') {
                navigator.clipboard.writeText(window.location.href);
                this.isCopied = true;
                let timer = setTimeout(() => {
                    this.isCopied = false;
                    clearTimeout(timer);
                }, 3000);
            }
            if (share === 'facebook') {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, '_blank');
            }
        }
    },
    async mounted() {
        // TODO: add logic load from api
        await this.loadPoem();
        this.elements.button = document.querySelector('.poem__opened-button');
        this.elements.buttonOpened = document.querySelector('.poem__opened-button-opened');
        this.elements.buttonOpenedX = document.querySelector('.poem__opened-button-opened polygon');
        this.elements.buttonDot = document.querySelector('.Ellipse_2');
        this.elements.img = document.querySelector('.poemimage');
        this.elements.data = document.querySelector('.poem__opened-data');
        this.elements.title = document.querySelector('.poem__opened-title');
        this.elements.text = document.querySelector('.poem__opened-text');
        this.elements.share = document.querySelector('.poem__opened-share');
        this.elements.comments = document.querySelector('.comments');
        this.elements.navigation = document.querySelector('.poem__opened-navigation');

        gsap.timeline()
            .to(this.elements.navigation, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1})
            .to(this.elements.title, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, '-=0.333')
            .to(this.elements.text, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, '-=0.333')
            .to(this.elements.button, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, '-=0.333')

        let list = document.querySelector('.poem__opened-text')
        this.keyValue = 1;
        list.addEventListener('scroll', () => {
                let scrollTop = list.scrollTop;
                let scrollHeight = list.scrollHeight;
                let offsetHeight = list.offsetHeight;
                let contentHeight = scrollHeight - offsetHeight;
                if (contentHeight <= scrollTop) {
                    this.scrollEnabled = false;
                }
                if (scrollTop === 0) {
                    this.scrollEnabled = true;
                }
            },
            false
        )

    },
    destroyed() {
        this.keyValue = 0;
        gsap.timeline()
            .to(this.elements.navigation, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1})
            .to(this.elements.title, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, '-=0.333')
            .to(this.elements.text, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, '-=0.333')
            .to(this.elements.button, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, '-=0.333')
    }
}
</script>

<style scoped>
.scroll_opened {
    top: 185px !important;
    height: initial !important;
}

.poem__scroll {
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    height: fit-content;
    right: 109px;
}

.scroll_disabled {
    opacity: 0;
}

.poem__opened-data_copy {
    margin-left: 5px;
}

.poem__opened-data_comments {
    cursor: pointer;
    color: var(--grey);
    transition: ease all 0.333s;
}

.poem__opened-data_comments:hover {
    opacity: 0.4 !important;
}

.poem__opened-button_svg {
    transition: ease all 0.333s;
}

.poem__opened-button:hover .poem__opened-button_svg {
    opacity: 0.4 !important;
}

.poem__opened-button-opened {
    position: absolute;
    width: 48px;
    height: 48px;
    right: 94px;
    bottom: 67vh;
    visibility: hidden;
    cursor: pointer;
    transform: rotate(45deg);

}

.poem__opened-button-opened_cls {
    transition: ease all 0.333s;
}

.poem__opened-button-opened:hover .poem__opened-button-opened_cls {
    opacity: 0.4 !important;
}

.poem__opened-button-opened polygon {
    visibility: hidden;
}

.poem__opened-button-opened path {
    stroke-width: 1px !important;
}

.poem__opened-button-opened .cls-2 {
    fill: var(--grey);
}

.poem__opened-data_social {
    margin-right: 10px;
    transition: ease all 0.333s;
}

.poem__opened-data_social:hover {
    opacity: 0.4;
}


.poem__opened-content {
    white-space: pre-line;

}

.poem__opened-next:hover {
    opacity: 0.4;
}



.poem__opened-next p {

    position: absolute;
    font-family: El Messiri;
    font-size: 20px;
    top: 0px;
    color: var(--color);
    transition: 0.666s ease all;
}


.poem__opened-button {
    position: absolute;
    width: 48px;
    height: 48px;
    right: 90px;
    bottom: 15vh;
    cursor: pointer;
    visibility: hidden;
    transform: translateX(200px);
}


#Ellipse_1 {
    fill: transparent;
    stroke: var(--color);
    transition: 0.666s ease all;
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Ellipse_1 {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 0px;
    top: 0px;
}

#Ellipse_2 {
    fill: var(--color);
    transition: 0.666s ease all;
}

.Ellipse_2 {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 19px;
    top: 19px;
}

#Path_3 {
    fill: transparent;
    stroke: var(--grey);
    transition: 0.666s ease all;
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_3 {
    overflow: visible;
    position: absolute;
    width: 16.453px;
    height: 13.358px;


}

#Path_2 {
    fill: transparent;
    stroke: var(--grey);
    transition: 0.333s ease all;
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_2 {
    overflow: visible;
    position: absolute;
    width: 16.453px;
    height: 13.358px;
    left: 0px;
    top: 0px;
    cursor: pointer;
    transition: ease all 0.666s;
}



.poem__opened-share {
    left: 412px;
    bottom: 35px;
    position: absolute;
    visibility: hidden;
    font-family: El Messiri;
    font-size: 16px;
    color: var(--grey);
    display: flex;
    transform: translateX(200px);
//transition: 0.666s ease all; letter-spacing: 0.2px;
}




.poem__opened-data-mobile {
    opacity: 0;
}


.Path_2:hover {
    opacity: 0.4;
}

@media screen and (min-width: 1200px) {
    .poem__opened-navigation {
        position: absolute;
        top: -80px;
    }
}

@media only screen and (max-device-width: 1023px) {

}



@media (min-width: 0px) and (max-width: 1023px) {
    .poem__scroll{
        display: none;
    }
    .Path_2{
        z-index: 100;
    }
    .poem__opened-content{
        padding-left: 30px;
        padding-right: 30px;
    }
    .poem__opened-block {
        width: 100vw;
        height: 60vh;
        left: 0;
        right: 0;
        position: relative;
        margin: auto;
        top: 0px;
    }

    #poem__opened {
        width: 100vw;
        height: 100vh;
    }

    .Path_2 {
        position: absolute;
        transform: rotate(90deg);
        left: 30px;
        top: 0px;
    }

    .Path_3 {
        position: absolute;
    //transform: rotate(90deg); right: 30px !important; top: 0px !important;
        transform: rotate(-90deg);
    }

    .poem__opened-next {
        position: absolute;
    //background-color: #e74c3c;
         height: 50px;
        width: 50vw;
        top: 0;
        right: 0;
    }


    .poem__opened-next {
        //position: absolute;
        //width: 131.718px;
        //height: 23.453px;
        //left: 894px;
        //top: 121.503px;
        //cursor: pointer;
        //transition: ease all 0.333s;
    }

    .poem__opened-next p {
        position: absolute;
        right: 70px;
        top: -5px;
    }

    .poem__opened-navigation {
        position: relative;
        height: 50px;
        margin-top: 128px;
    }

    /*.poem__opened-next{
        position: absolute;
        right: 30px;
        top: 129px;
    }*/
    .poem__opened-title {

        position: relative;
        font-family: El Messiri;
        font-size: 1.5rem;
        padding-left: 30px;
        padding-right: 30px;
        color: var(--color);
        visibility: hidden;
        transform: translateX(0px);
    //transition: 0.666s ease all;
    }

    .poem__opened-text {
    //left: 412px; //top: 190px; //position: absolute; //width: 728px; max-height: 45vh; line-height: 30px;
        font-family: El Messiri;
        font-size: 1.2rem;
        max-height: 65vh !important;
    //padding-left: 30px; //padding-right: 30px; color: var(--color); //transition: 0.666s ease all; overflow-y: scroll; overflow-x: hidden; visibility: hidden; transform: translateX(200px);
    }

    .poem__opened-data-mobile {
        position: relative;
        font-family: El Messiri;
        font-size: 1rem;
        padding-left: 30px;
        padding-right: 30px;
        color: var(--logo-bg);
        letter-spacing: 0.2px;
        opacity: 1;
        margin-top: 50px;
        margin-bottom: 0;
    }

    .poem__opened-button {
        display: none;
    }

    .poemimage {
        display: none;
    }

    .poem__opened-data{
        display: none;
    }


    .poem__opened-data-other {
        position: relative;
        font-family: El Messiri;
        font-size: 1rem;
        color: var(--logo-bg);
    //transition: 0.666s ease all; letter-spacing: 0.2px;
    }

    .poem__opened-data-other span:nth-child(1) {
        cursor: pointer;
    }

    .poem__opened-data-other span:nth-child(2) {
        margin-right: 10px;
    }

    .poem__opened-data-other span svg {
        width: 25px;
        margin-top: -2px;
        cursor: pointer;
    }

    .poem__opened-data-other span svg .cls-1 {
        fill: var(--grey);
        transition: 0.666s ease all;
    }

    .poem__opened-data-other span:nth-child(1) {
        margin-left: 30px;
        margin-top: -2px;
    }

    .poem__opened-data-other span:nth-child(2) {
        margin-left: 10px;
        margin-top: -2px;
    }

    .poem__opened-data-mobile-img{
        width: 100vw;
        margin-top: 50px;
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0));
    }
}

@media screen and (min-width: 1024px) {

    .poem__opened-next {
        position: absolute;
        width: 131.718px;
        height: 23.453px;
        left: 894px;
        top: 121.503px;
        cursor: pointer;
        transition: ease all 0.333s;
    }
    .poem__opened-data {
        left: 412px;
        bottom: 50px;
        position: absolute;
        visibility: hidden;
        font-family: El Messiri;
        font-size: 20px;
        color: var(--logo-bg);
        transform: translateX(200px);
    //transition: 0.666s ease all; letter-spacing: 0.2px;
    }

    .poem__opened-data span:nth-child(1) {
        cursor: pointer;
    }

    .poem__opened-data span:nth-child(2) {
        margin-right: 10px;
    }

    .poem__opened-data span svg {
        width: 25px;
        margin-top: -2px;
        cursor: pointer;
    }

    .poem__opened-data span svg .cls-1 {
        fill: var(--grey);
        transition: 0.666s ease all;
    }

    .poem__opened-data span:nth-child(1) {
        margin-left: 20px;
        margin-top: -2px;
    }

    .poem__opened-data span:nth-child(2) {
        margin-left: 35px;
        margin-top: -2px;
    }
    .poem__opened-data-mobile {
        display: none;
    }

    .poem__opened-navigation {
        visibility: hidden;
        transform: translateX(200px);
    }

    .Path_3 {
        right: 0px;
        top: 6px;
        transform: rotate(-90deg);
    }

    .poem__opened-title {
        left: 412px;
        top: 100px;
        position: absolute;
        font-family: El Messiri;
        font-size: 42px;
        color: var(--color);
        visibility: hidden;
        transform: translateX(200px);
    //transition: 0.666s ease all;
    }

    .poem__opened-text {
        left: 412px;
        top: 190px;
        position: absolute;
        width: 728px;
        max-height: 65vh;
        line-height: 30px;
        font-family: El Messiri;
        font-size: 18pt;
        color: var(--color);
    //transition: 0.666s ease all; overflow-y: scroll; overflow-x: hidden; visibility: hidden; transform: translateX(200px);
    }

    .poem__opened-data-mobile-img {
        display: none;
    }

    .poemimage {
        position: absolute;
        right: 0;
        bottom: 0;
        max-height: 60vh;
        max-width: 50vw;
        transform: translateX(100px);
        visibility: hidden;
        mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0));
    }

    .poem__opened-data-other{
        display: none;
    }

    .Path_2{
        left: 412px;
        top: 130px;
        transform: rotate(90deg);
    }

}

@media (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1920px){
    .poem__opened-text {
        left: 412px;
        top: 190px;
        position: absolute;
        width: 728px;
        max-height: 60vh;
        line-height: 30px;
        font-family: El Messiri;
        font-size: 18pt;
        color: var(--color);
    //transition: 0.666s ease all; overflow-y: scroll; overflow-x: hidden; visibility: hidden; transform: translateX(200px);
    }
}
@media screen and (max-width: 1199px) {


    /*.poem__opened-title {
        position: relative;
        left: auto;
        top: auto;
        font-size: 20pt;
        width: auto;
        margin-bottom: 0;
    }

    .poem__opened-text {
        position: relative;
        left: auto;
        top: auto;
        font-size: 10pt;
        width: auto;
        line-height: 20px;
    }



    .poem__opened-data-mobile {
        position: relative;
        left: auto;
        top: auto;
        font-family: El Messiri;
        font-size: 10pt;
        margin-top: 35px;
        color: rgba(87, 87, 86, 1);
        letter-spacing: 0.2px;
        opacity: 1;
    }

    .poem__opened-button {
        display: none;
    }

    .poem__opened-data {
        display: none;
    }

    img {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: auto;
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0));
    }*/
}

@media (min-width: 1024px) and (max-width: 1440px) {

    .poem__opened-data{
        bottom: 10px !important;
        z-index: 100 !important;
    }
    .poem__opened-text{
        max-height: 55vh !important;
    }

    .poem__opened-button-opened {
        bottom: 62vh !important;
    }
}

@media (min-width: 1024px) and (max-width: 1365px) {
    .Path_2{
        left: 310px;
        top: 45px;
        transform: rotate(90deg);
    }

    .poem__opened-next {
        top: 45px;
    }

    .poem__opened-title{
        left: 310px;
    }

    .poem__opened-text{
        left: 310px;
    }

    .scroll_opened{
        display: none;
    }

    .poem__opened-data {
        left: 310px;
        bottom: 10px;
    }

    .poem__opened-data_comments{
        margin-left: 0px !important;
        margin-right: 20px !important;
    }

    .poem__opened-data_social{
        margin-right: 10px;
    }

    .sociallink{
        display: none;
    }
}

@media (min-width: 1366px) {
    .poem__opened-data br{
        display: none;
    }
}

</style>