<template>
  <div class="home">
<!--    TODO: load image from backend-->
<!--    <img src="../assets/img/Mask_Group_1.png" alt="img_opening">-->
      <div class="home__img">
          <img alt="vándornapló_fooldal" :src="image">
      </div>


<!--    </svg>-->

<!--      TODO: load text from backend-->
      <h1>{{text}}</h1>
<!--      <h1>{{width}}</h1>-->


  </div>
</template>

<script>
import {gsap} from "gsap";

export default {
  name: "HomeView",
    data() {
        return {
            text: null,
            image: null,
        }
    },
    methods:{
        async loadContent() {
            let result = await this.apiRequest('GET', '/content/all');
            if (result?.data) {
                this.text = result.data[0].home;
                this.image = result.data[0].homeImage;
            }
        },
    },
    async mounted() {
        await this.loadContent();
        let img = document.querySelector('.home img');
        let h1 = document.querySelector('.home h1');
        gsap.timeline()
            .to(img, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, )
            .to(h1, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, '-=0.333');
    },
    destroyed() {
        let img = document.querySelector('.home img');
        let h1 = document.querySelector('.home h1');
        gsap.timeline()
            .to(img, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, )
            .to(h1, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, '-=0.333');
    }
}
</script>

<style scoped>
@media (min-width:0px) and (max-width:1023px) {
    img {
        position: absolute;
        /*height: 90vh;*/
        width: 100vw;
    //height: 100vh;
        right: 0;
        bottom: 0;
        overflow: visible;
        transform: initial;
        mask-image: linear-gradient(to top, rgba(0,0,0,1) 60%, rgba(0,0,0,0));
        visibility: hidden;
    }

    h1 {
        //left: 27.55vw;
        top: 20vh;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        overflow: visible;
        /*height: 22.56vw;*/
        line-height: 5vh;
        font-family: El Messiri,serif;
        font-size: 6vw;
        width: 80vw;
        color: var(--color);
        transform: initial;
        visibility: hidden;
    //transition: 0.666s ease all;
    }
}
@media (min-width:320px)  {
}
@media (min-width:481px) and (max-width:1023px) {
    h1 {
    //left: 27.55vw;
        top: 15vh;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        overflow: visible;
        /*height: 22.56vw;*/
        line-height: 50px;
        font-family: El Messiri,serif;
        font-size: 4vw;
        width: 80vw;
        color: var(--color);
        transform: translateX(200px);
        visibility: hidden;
        //text-align: center;
    //transition: 0.666s ease all;
    }
}
@media (min-width:641px) and (max-width:1023px) {
    h1 {
    //left: 27.55vw;
        top: 15vh;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        overflow: visible;
        /*height: 22.56vw;*/
        line-height: 6vh;
        font-family: El Messiri,serif;
        font-size: 5vw;
        width: 80vw;
        color: var(--color);
        transform: translateX(200px);
        visibility: hidden;
        //text-align: center;
    //transition: 0.666s ease all;
    }
}

@media (min-width:801px) and (max-width:1023px)  {
    h1 {
    //left: 27.55vw;
        top: 15vh;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        overflow: visible;
        /*height: 22.56vw;*/
        line-height: 6vh;
        font-family: El Messiri,serif;
        font-size: 5vw;
        width: 80vw;
        color: var(--color);
        transform: translateX(200px);
        visibility: hidden;
    //text-align: center;
    //transition: 0.666s ease all;
    }
}

@media (min-width:1024px) {
    img {
        position: absolute;
        /*height: 90vh;*/
        max-width: 50vw;
        //max-height: 100vh;
        //height: 100vh;
        right: 0;
        bottom: 0;
        overflow: visible;
        transform: translateX(200px);
        mask-image: linear-gradient(to left, rgba(0,0,0,1) 60%, rgba(0,0,0,0));
        visibility: hidden;
    }

    h1 {
        left: 27.55vw;
        bottom: 8vh;
        position: absolute;
        overflow: visible;
        width: 41.04vw;
        /*height: 22.56vw;*/
        line-height: 7vh;
        font-family: El Messiri,serif;
        font-size: 2.3vw;
        color: var(--color);
        transform: translateX(200px);
        visibility: hidden;
    //transition: 0.666s ease all;
    }
}

@media (min-width:1024px) and (max-width: 1365px) {
    h1 {
        left: 310px;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    img {
        height: 100vh !important;
        width: auto !important;
        max-width: initial !important;
        right: -10vw;
    }
}
</style>