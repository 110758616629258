<template>
  <div id="menu">
    <div v-if="!$route.path.includes('/admin')" class="menu__regular">
      <p @click="changeRoute('/versek')" :class="{ active: $route.path.includes('/versek')}">

<!--          <svg xml:space="preserve" viewBox="0 0 69.172 14.975"  xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1">-->
<!--	<path d="M1.357,12.26 10.807,2.81 20.328,12.332-->
<!--		29.781,2.879 39.223,12.321 48.754,2.79 58.286,12.321 67.815,2.793 " ></path>-->
<!--	</svg>-->

          <svg viewBox="0 0 109.34 3.35" style="margin-top: 9px">
              <path  d="M109.25,1.67l.09,0h-.17L100.4.09,97.58,0H89.11l0,0H50.72l0,0H44.19l0,0h-12l-.13,0H30.19l-.12,0H19.54l0,0H17.8l-.57,0L15.76,0,14.1.13.29,1.65H0l.14,0L0,1.69H.29L13.77,3.34h4s.4,0,.41,0l1.37,0,0,0H30.07l.12,0h1.86l.13,0h12l0,0h6.49l0,0H89.06l0,0,3.2,0s.29,0,.31,0l.73,0,4.9-.08a11.87,11.87,0,0,0,1.57-.13l9.35-1.42h.17Z"/>
          </svg>



<!--          <svg viewBox="0 0 110.29 3.85"><path d="M96.65,0l-4,0A5.21,5.21,0,0,1,88,1.56,9,9,0,0,1,83.6.05l-5.68,0a7.16,7.16,0,0,1-1.31.1c-2.91,0-3,1-6.62-.07l-9.33,0a8.81,8.81,0,0,1-4.53,1A9.6,9.6,0,0,1,52.25.17L15.54.31C11,1.53,10.76,1.88,9.41,1.56L6.76.93,0,2.16,11.53,3.85h.28A5.43,5.43,0,0,1,15.92,2.8a9.28,9.28,0,0,1,3.62,1.05H30.07a6.16,6.16,0,0,1,2.11,0h12a7.9,7.9,0,0,1,3.62-.59,7.18,7.18,0,0,1,2.95.59H89.06c3.86-1.06,4.16-1.36,5.43-1.05l4.42,1.05h3.44l7.94-1.92Z"/></svg>-->
<!--          <svg viewBox="0 0 455.555 48"><path d="M0 2c37.962 0 37.962 44 75.924 44s37.962-44 75.924-44 37.962 44 75.923 44c37.962 0 37.962-44 75.925-44 37.965 0 37.965 44 75.929 44s37.965-44 75.93-44" /><path d="M0 2c37.962 0 37.962 44 75.924 44s37.962-44 75.924-44 37.962 44 75.923 44c37.962 0 37.962-44 75.925-44 37.965 0 37.965 44 75.929 44s37.965-44 75.93-44"/></svg>-->
          <span>Napló</span>
      </p>
      <p @click="changeRoute('/vandor')" :class="{ active: $route.path === '/vandor' }">


          <svg viewBox="0 0 109.34 3.35" style="margin-top: 9px">
              <path  d="M109.25,1.67l.09,0h-.17L100.4.09,97.58,0H89.11l0,0H50.72l0,0H44.19l0,0h-12l-.13,0H30.19l-.12,0H19.54l0,0H17.8l-.57,0L15.76,0,14.1.13.29,1.65H0l.14,0L0,1.69H.29L13.77,3.34h4s.4,0,.41,0l1.37,0,0,0H30.07l.12,0h1.86l.13,0h12l0,0h6.49l0,0H89.06l0,0,3.2,0s.29,0,.31,0l.73,0,4.9-.08a11.87,11.87,0,0,0,1.57-.13l9.35-1.42h.17Z"/>
          </svg>

          <span>Vándor</span>

<!--          <svg viewBox="0 0 110.29 3.85"><path d="M96.65,0l-4,0A5.21,5.21,0,0,1,88,1.56,9,9,0,0,1,83.6.05l-5.68,0a7.16,7.16,0,0,1-1.31.1c-2.91,0-3,1-6.62-.07l-9.33,0a8.81,8.81,0,0,1-4.53,1A9.6,9.6,0,0,1,52.25.17L15.54.31C11,1.53,10.76,1.88,9.41,1.56L6.76.93,0,2.16,11.53,3.85h.28A5.43,5.43,0,0,1,15.92,2.8a9.28,9.28,0,0,1,3.62,1.05H30.07a6.16,6.16,0,0,1,2.11,0h12a7.9,7.9,0,0,1,3.62-.59,7.18,7.18,0,0,1,2.95.59H89.06c3.86-1.06,4.16-1.36,5.43-1.05l4.42,1.05h3.44l7.94-1.92Z"/></svg>-->
      </p>
      <p @click="changeRoute('/kapcsolat')" :class="{ active: $route.path === '/kapcsolat' }">


          <svg viewBox="0 0 109.34 3.35" style="margin-top: 9px; width: 90%;">
              <path  d="M109.25,1.67l.09,0h-.17L100.4.09,97.58,0H89.11l0,0H50.72l0,0H44.19l0,0h-12l-.13,0H30.19l-.12,0H19.54l0,0H17.8l-.57,0L15.76,0,14.1.13.29,1.65H0l.14,0L0,1.69H.29L13.77,3.34h4s.4,0,.41,0l1.37,0,0,0H30.07l.12,0h1.86l.13,0h12l0,0h6.49l0,0H89.06l0,0,3.2,0s.29,0,.31,0l.73,0,4.9-.08a11.87,11.87,0,0,0,1.57-.13l9.35-1.42h.17Z"/>
          </svg>
          <span>Kapcsolat</span>
<!--          <svg viewBox="0 0 110.29 3.85"><path d="M96.65,0l-4,0A5.21,5.21,0,0,1,88,1.56,9,9,0,0,1,83.6.05l-5.68,0a7.16,7.16,0,0,1-1.31.1c-2.91,0-3,1-6.62-.07l-9.33,0a8.81,8.81,0,0,1-4.53,1A9.6,9.6,0,0,1,52.25.17L15.54.31C11,1.53,10.76,1.88,9.41,1.56L6.76.93,0,2.16,11.53,3.85h.28A5.43,5.43,0,0,1,15.92,2.8a9.28,9.28,0,0,1,3.62,1.05H30.07a6.16,6.16,0,0,1,2.11,0h12a7.9,7.9,0,0,1,3.62-.59,7.18,7.18,0,0,1,2.95.59H89.06c3.86-1.06,4.16-1.36,5.43-1.05l4.42,1.05h3.44l7.94-1.92Z"/></svg>-->
      </p>
    </div>

    <div v-else class="menu__admin">
        <p @click="changeRoute('/admin/versek')" :class="{ active: $route.path === '/admin/versek' }">Napló</p>
        <p @click="changeRoute('/admin/kommentek')" :class="{ active: $route.path === '/admin/kommentek' }">Kommentek</p>
        <p @click="changeRoute('/admin/kategoriak')" :class="{ active: $route.path === '/admin/kommentek' }">Kategóriák</p>
        <p @click="changeRoute('/admin/fooldal')" :class="{ active: $route.path === '/admin/fooldal' }">Fõoldal</p>
        <p @click="changeRoute('/admin/vandor')" :class="{ active: $route.path === '/admin/vandor' }">Vándor</p>
        <p @click="changeRoute('/admin/kapcsolat')" :class="{ active: $route.path === '/admin/kapcsolat' }">Kapcsolat</p>
        <p @click="openStat()" :class="{ active: $route.path === '/admin/statisztika' }">Statisztika</p>
        <p @click="logout">logout</p>


    </div>
  </div>


</template>

<script>
import {gsap} from "gsap";

export default {
  name: "MenuComponent",
  methods:{
      logout(){
          this.$cookie.set('token', 'null');
          this.$router.push('/admin')
      },
      openStat(){
          window.open("https://analytics.google.com/analytics/web/#/p404069820/reports/intelligenthome", "_blank");
      }
  },
  mounted() {
      let menu = document.querySelectorAll('#menu div p');
      gsap.timeline()
          .to(menu, {autoAlpha: 1, duration: 0.666, stagger: 0.1}, '+=1.666')
  }
}
</script>

<style scoped>
.menu__regular{
    //line-height: 77px;
}
#menu {
  left: 60px;
  //top: 265px;
  position: absolute;
  white-space: nowrap;
  text-align: center;
  //max-height: 40%;
  width: 200px;
    z-index: 30;

}

#menu p{
  cursor: pointer;
  font-family: El Messiri, serif;
  font-size: 1.7rem;
    position: relative;
  color: var(--color);
    //transition: 0.666s ease all;
    visibility: hidden;
    margin-bottom: 77px;
    //position: re;
}

#menu p svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    top: 0;
    width: 70%;
    height: 20px;
}

#menu p span{
    z-index: 10 !important;
    position: inherit;
}

#menu p svg path {
    fill: transparent;
    stroke-width: 1px;
    stroke: var(--logo-bg);
    opacity: 0.5;
    stroke-dasharray: 547.4111328125;
    stroke-dashoffset: 547.4111328125;
    transition: all 1s cubic-bezier(0.29, 0.68, 0.74, 1.02);
}

#menu p:hover svg path {
    stroke-dashoffset: 0;
    fill: var(--logo-bg)
}


#menu .active svg path{
    stroke-dashoffset: 0;
    fill: var(--logo-bg)
}

@media (min-width:0px) and (max-width:1023px) {
    #menu{
        display: none;
    }
}
@media (min-width:320px)  {
}
@media (min-width:481px) and (max-width:1023px) {
}
@media (min-width:641px) and (max-width:1023px) {
}

@media (min-width:801px) and (max-width:1023px)  {
}

@media (min-width:1024px) {
    #menu{
        display: block;
    }


}

@media only screen and (max-device-width: 1023px) {
    .menu__regular p{
        margin-bottom: 0px !important;
    }

    #menu{
        top: 200px !important;
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .menu__admin p{
      margin-bottom: 20px !important;
        font-size: 1.4rem !important;
    }

    .menu__regular p{
        margin-bottom: 10px !important;
    }

    #menu {
        top: 220px;
    }
}

@media (min-width: 1440px)  {
    #menu {
    //bottom: 0;
    //top: 0;
    //margin: auto;
        top: 32vh !important;
    }

    .menu__regular p{
        margin-bottom: 40px !important;
    }

    .menu__admin p{
        margin-bottom: 20px !important;
        //font-size: 1.4rem !important;
    }
}

@media (min-width: 1024px)  and (max-width: 1439px){
    .menu__regular p{
        margin-bottom: 10px !important;
    }

    #menu {
    //bottom: 0;
    //top: 0;
    //margin: auto;
        top: 32vh !important;
    }
}

/*@media (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1440px){
    .menu__regular p{
        margin-bottom: 20px !important;
    }
}

@media (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1920px){
    .menu__admin p{
        margin-bottom: 20px !important;
        font-size: 1.4rem !important;
    }

    .menu__regular p{
        margin-bottom: 20px !important;
    }

    #menu {
        //bottom: 0;
        //top: 0;
        //margin: auto;
        top: 30vh !important;
    }
}
@media (min-width:1440px) and (-webkit-max-device-pixel-ratio: 1) {
    .menu__admin p{
        margin-bottom: 40px !important;
        font-size: 1.7rem !important;
    }

    .menu__regular p{
        margin-bottom: 40px !important;
    }

    #menu {
        top: 40vh;
    }
}*/
</style>