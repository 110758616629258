<template>
  <div class="traveller">


<!--      <img src="../assets/img/vandor.png" />-->
      <img alt="vándornapló_vándor" :src="image" />
      <p>{{text}}</p>
  </div>
</template>

<script>
import {gsap} from "gsap";

export default {
  name: "TravellerView",
    data() {
        return {
            text: null,
            image: null,
        }
    },
    methods:{
        async loadContent() {
            let result = await this.apiRequest('GET', '/content/all');
            if (result?.data) {
                this.text = result.data[0].about;
                this.image = result.data[0].aboutImage;
            }
        },
    },
    async mounted() {
        await this.loadContent();
        let img = document.querySelector('.traveller img');
        let p = document.querySelector('.traveller p');
        gsap.timeline()
            .to(img, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, )
            .to(p, {autoAlpha: 1, x: 0, duration: 0.666, stagger: 0.1}, '-=0.333');
    },
    destroyed() {
        let img = document.querySelector('.traveller img');
        let p = document.querySelector('.traveller p');
        gsap.timeline()
            .to(img, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, )
            .to(p, {autoAlpha: 0, x: 200, duration: 0.666, stagger: 0.1}, '-=0.333');
    }
}
</script>

<style scoped>



@media (min-width:0px) and (max-width:1024px) {
    img {
        position: absolute;
        /*height: 90vh;*/
        width: 100vw;
    //height: 100vh;
        right: 0;
        bottom: -25vh;
        overflow: visible;
        transform: initial;
        mask-image: linear-gradient(to top, rgba(0,0,0,1) 60%, rgba(0,0,0,0));
        visibility: hidden;
    }

    p {
        top: 20vh;
        position: absolute;
        width: 80vw;
        /*height: 22.56vw;*/
        height: 40vh;
        overflow-y: scroll;
        line-height: 5vh;
        font-family: El Messiri,serif;
        font-size: 6vw;
        color: var(--color);
        transform: initial;
        visibility: hidden;

        left: 0;
        right: 0;
        margin: auto;
    //transition: 0.666s ease all;
    }
}
@media (min-width:320px)  {
}
@media (min-width:481px) and (max-width:1024px) {
}
@media (min-width:641px) and (max-width:1024px) {
}

@media (min-width:1024px) and (max-width: 1365px) {


    p {
        left: 310px;
        top: 8vh;
        position: absolute;
        overflow: visible;
        width: 41.04vw;
        /*height: 22.56vw;*/
        line-height: 4vh;
        font-family: El Messiri,serif;
        font-size: 1.9vw;
        color: var(--color);
        transform: translateX(200px);
        visibility: hidden;
    //transition: 0.666s ease all;
    }
}

@media only screen and (max-device-width: 1023px) {

}

@media (min-width:1024px) {


    img {
        position: absolute;
        /*height: 90vh;*/
        max-width: 50vw;
    max-height: 100vh;
    //height: 100vh;
        right: 0;
        bottom: 0;
        overflow: visible;
        transform: translateX(200px);
        mask-image: linear-gradient(to left, rgba(0,0,0,1) 60%, rgba(0,0,0,0));
        visibility: hidden;
    }


}

@media (min-width: 1366px) {
    p {
        left: 27.55vw;
        bottom: 8vh;
        position: absolute;
        overflow: visible;
        width: 41.04vw;
        /*height: 22.56vw;*/
        line-height: 5vh;
        font-family: El Messiri,serif;
        font-size: 1.7vw;
        color: var(--color);
        transform: translateX(200px);
        visibility: hidden;
    //transition: 0.666s ease all;
    }
}
@media (min-width:1281px) {

}
</style>