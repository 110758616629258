<template>
  <div>
      <svg class="admin__poem-new-arrow" viewBox="7496 5161 16.453 13.358" @click="changeRoute('/admin/versek')">
          <path id="Path_3" d="M 7496 5161 L 7504.3740234375 5174.3583984375 L 7512.453125 5161">
          </path>
      </svg>
    <p class="admin__contact-title">Kapcsolat szerkesztése</p>
    <b-form-textarea
        class="admin__contact-edit-text"
        v-model="form.contact"
        placeholder="Kapcsolat szöveg"
        rows="3"
        max-rows="6">
    </b-form-textarea>

      <p class="admin__poem-new-create" @click="editContact" :class="{create_disabled: !this.fieldsFilled}">
          <span v-if="!isCreated">Szerkesztés</span>
          <span v-else class="admin__poem-new-created">Szerkesztve!</span>
      </p>
  </div>
<!--  TODO: add button-->
</template>

<script>
export default {
  name: "AdminTravellerView",
    data() {
        return {
            form: {
                _id: null,
                contact: null,
            },
            isCreated: false,
        }
    },
    computed: {
        // a computed getter
        fieldsFilled() {
                return this.form.contact !== null || this.form?.contact?.length > 0;
        }
    },
    methods:{
        async editContact(){
            let result = await this.apiRequest('PUT', `/content/edit/${this.form._id}`, this.form);
            // this.url = null;
            // this.base64 = null;
            if(result?.status){
                this.isCreated = true;
                let timer = setTimeout(() => {
                    this.isCreated = false;
                    clearTimeout(timer);
                }, 3000);

            }
        },
        async loadContent(){
            let result = await this.apiRequest('GET', '/content/all');
            if(result?.data){
                this.form.contact = result.data[0].contact;
                this.form._id = result.data[0]._id;
            }
        },
    },
    async mounted() {
        await this.loadContent();
    }
  // TODO: load text
  // TODO: connect to api
}
</script>

<style scoped>

@media (min-width: 1024px) and (max-width: 1440px) {
    .admin__poem-new-arrow {
        left: 380px !important;
    }

    .admin__contact-title, .admin__contact-edit-text {
        left: 442px !important;
    }

    .admin__contact-edit-text {
        width: 820px !important;
    }

    #preview img {
        max-height: 70px !important;
        max-width: 70px !important;
    }

    #preview{
        left: 442px !important;
        bottom: 28px !important;
    }

    .image-upload{
        left: 522px !important;
        bottom: 40px !important;
    }

    .admin__poem-new-create{
        left: 1135px !important;
        bottom: initial !important;
        top: 695px !important;
    }


}

.admin__poem-new-created{
    color: #823033 !important;
}

.admin__poem-new-create{
    font-family: El Messiri;
    font-size: 20px;
    color: var(--color);
    letter-spacing: 2.41px;
    cursor: pointer;
    transition: ease all 0.4s;
    position: absolute;
    left: 1720px;
    bottom: 170px;
}

.admin__poem-new-arrow {
    overflow: visible;
    position: absolute;
    width: 16.453px;
    height: 13.358px;
    /*transform: matrix(1,0,0,1,479.0942,130.0503) rotate(90deg);*/
    transform-origin: center;
    left: 480px;
    top: 100px;
    rotate: 90deg;
    cursor: pointer;
}

.admin__poem-new-arrow path{
    fill: transparent;
    stroke: rgba(130,48,51,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

#Path_3 {

}

.admin__contact-title {
  left: 542px;
  top: 88px;
  position: absolute;


  line-height: 36px;
  text-align: center;
  font-family: El Messiri;
  font-size: 28px;
  color: var(--color);
}

.admin__contact-edit-text{
  width: 1020px;
  position: absolute;
  left: 542px;
  top: 180px;
  height: 60vh !important;
}

</style>