import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    welcomeScreen: true,
    mode: 'dark',
    menuPoem: [],
    defaultMenuPoem: true,
    hamburger: false,
    nextUrl: null,
    comments: null,
    colors: [],
    logoBgColor: 'alap',
    tempMenuPoem: [],
  },
  getters:{
    welcomeScreen: state => {
      return state.welcomeScreen;
    },
    mode: state => {
      return state.mode;
    },
    menuPoem: state => {
      return state.menuPoem;
    },
    hamburger: state => {
      return state.hamburger;
    },
    defaultMenuPoem: state => {
      return state.defaultMenuPoem;
    },
    nextUrl: state => {
      return state.nextUrl;
    },
    comments: state => {
      return state.comments;
    },
    colors: state => {
      return state.colors;
    },
    logoBgColor: state => {
      return state.logoBgColor;
    },
    tempMenuPoem: state => {
      return state.tempMenuPoem;
    },
  },
  mutations: {
    welcomeScreen: function (state, newValue){
      state.welcomeScreen = newValue;
    },
    setMode: function (state, newValue){
      state.mode = newValue;
      if(newValue === 'light'){
        document.documentElement.style.setProperty('--bg', 'white');
        document.documentElement.style.setProperty('--color', 'black');
        document.documentElement.style.setProperty('--logo-bg', '#c6c6c5');
        document.documentElement.style.setProperty('--logo-bg-default', '#c6c6c5');
        document.documentElement.style.setProperty('--logo-sigil', '#1d1d1b');
        document.documentElement.style.setProperty('--grey', '#575756');
        document.documentElement.style.setProperty('--admin_comment_grey', '#212121');

        document.documentElement.style.setProperty('--poem_szellem', state.colors[0].colorLight);
        document.documentElement.style.setProperty('--poem_lelek', state.colors[1].colorLight);
        document.documentElement.style.setProperty('--poem_test', state.colors[2].colorLight);
        document.documentElement.style.setProperty('--poem_szellemlelek', state.colors[3].colorLight);
        document.documentElement.style.setProperty('--poem_szellemtest', state.colors[4].colorLight);
        document.documentElement.style.setProperty('--poem_lelektest', state.colors[5].colorLight);
        document.documentElement.style.setProperty('--poem_szellemlelektest', state.colors[6].colorLight);
      } else{
        document.documentElement.style.setProperty('--bg', 'black');
        document.documentElement.style.setProperty('--color', 'white');
        document.documentElement.style.setProperty('--logo-bg', '#575756');
        document.documentElement.style.setProperty('--logo-bg-default', '#575756');
        document.documentElement.style.setProperty('--logo-sigil', '#fff');
        document.documentElement.style.setProperty('--grey', '#c2c2c2');
        document.documentElement.style.setProperty('--admin_comment_grey', '#f5f5f5');

        document.documentElement.style.setProperty('--poem_szellem', state.colors[0].colorDark);
        document.documentElement.style.setProperty('--poem_lelek', state.colors[1].colorDark);
        document.documentElement.style.setProperty('--poem_test', state.colors[2].colorDark);
        document.documentElement.style.setProperty('--poem_szellemlelek', state.colors[3].colorDark);
        document.documentElement.style.setProperty('--poem_szellemtest', state.colors[4].colorDark);
        document.documentElement.style.setProperty('--poem_lelektest', state.colors[5].colorDark);
        document.documentElement.style.setProperty('--poem_szellemlelektest', state.colors[6].colorDark);
      }
    },
    setMenuPoem: function (state, newValue){
      state.menuPoem = newValue;
    },
    setTempMenuPoem: function (state, newValue){
      state.tempMenuPoem = newValue;
    },
    closeMenuPoem: function (state){
      state.menuPoem = [];
    },
    toggleHamburger: function (state){
      state.hamburger = !state.hamburger;
    },
    setDefaultMenuPoem: function (state){
      state.defaultMenuPoem = !state.defaultMenuPoem;
    },
    setNextUrl: function (state, newValue){
      state.nextUrl = newValue;
    },
    setComments: function (state, newValue){
      state.comments = newValue;
    },
    setColors: function (state, newValue){
      state.colors = newValue;
    },
    setLogoColor: function (state, newValue){
      state.logoBgColor = newValue;
    },
  },
  actions: {
  },
  modules: {
  }
})
