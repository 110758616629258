

<template>
  <div class="light_switcher" @click="toggleLight">
      <p class="darklighttext">
          <span  v-if="lightMode === 'light'">
              Váltás éjszakára
          </span>
          <span v-else>Váltás nappalra</span>
      </p>
      <transition name="fade" mode="out-in">




          <svg v-if="lightMode === 'light'"  viewBox="0 0 50.16 50.16">
              <path class="cls-2" d="M30.76,24.63a8.36,8.36,0,0,1-.36,2.88A9.07,9.07,0,0,1,29,30.1,18.27,18.27,0,0,1,25.2,34c-.08.07-.34.24-.76.52l-.87.57L20.1,37.3l-.05-1,2.88-2.79a19.18,19.18,0,0,0,3.27-4.23A12.19,12.19,0,0,0,27,26.6a9.87,9.87,0,0,0,.17-2.31,5.68,5.68,0,0,0-.28-1.57c-.15-.4-.33-.83-.53-1.27a16.24,16.24,0,0,0-1-1.74,20.28,20.28,0,0,0-1.63-2.25L22,15.55a17,17,0,0,0-1.89-2V12.4l3.08,1.94,1.79,1.18a23.45,23.45,0,0,1,4,3.55,9.91,9.91,0,0,1,1.34,2.41A10.38,10.38,0,0,1,30.76,24.63Z"/>
              <path class="cls-2" d="M26.31,1.11q-.61,0-1.23,0c-.41,0-.83,0-1.23,0,.4,0,.82,0,1.23,0S25.9,1.09,26.31,1.11Z"/>
              <path class="cls-2" d="M49.05,26.31q0-.61,0-1.23c0-.41,0-.83,0-1.23,0,.4,0,.82,0,1.23S49.07,25.9,49.05,26.31Z"/>
              <path class="cls-2" d="M1.08,25.08a24,24,0,0,0,48,1.23q0-.61,0-1.23c0-.41,0-.83,0-1.23A24,24,0,0,0,26.31,1.11q-.61,0-1.23,0c-.41,0-.83,0-1.23,0A24,24,0,0,0,1.08,25.08Zm24-22a22,22,0,1,1-22,22A22,22,0,0,1,25.08,3.08Z"/>
          </svg>

          <svg v-else viewBox="0 0 50.16 50.16">
              <!--              <path class="" d="M49.05,23.85A24,24,0,0,0,26.31,1.11,24,24,0,0,1,49.05,23.85Z"/>-->
              <!--              <path d="M25.08,47.08a22,22,0,1,0-22-22A22,22,0,0,0,25.08,47.08Z"/>-->
              <path class="cls-2" d="M25.08,49.08a24,24,0,0,1-1.23-48,24,24,0,1,0,25.2,25.2A24,24,0,0,1,25.08,49.08Z"/>
              <path class="cls-2" d="M26.31,1.11q-.61,0-1.23,0c-.41,0-.83,0-1.23,0,.4,0,.82,0,1.23,0S25.9,1.09,26.31,1.11Z"/>
              <path class="cls-2" d="M49.05,26.31q0-.61,0-1.23c0-.41,0-.83,0-1.23,0,.4,0,.82,0,1.23S49.07,25.9,49.05,26.31Z"/>
              <path class="cls-2" d="M1.08,25.08a24,24,0,0,0,48,1.23q0-.61,0-1.23c0-.41,0-.83,0-1.23A24,24,0,0,0,26.31,1.11q-.61,0-1.23,0c-.41,0-.83,0-1.23,0A24,24,0,0,0,1.08,25.08Zm24-22a22,22,0,1,1-22,22A22,22,0,0,1,25.08,3.08Z"/>
              <path class="cls-2" d="M32.1,10.45l0,1.17a24.19,24.19,0,0,0-2.35,1.88l-1.59,1.31-2,1.66A17.06,17.06,0,0,1,28,18.28a11.21,11.21,0,0,1,1.67,2.95,9.44,9.44,0,0,1,.47,1.53,7.66,7.66,0,0,1,.15,1.54,9.77,9.77,0,0,1-.56,3.24,12,12,0,0,1-1.59,3l-1.9,2.15,1.22.9,1.2,1a9.24,9.24,0,0,1,1.28.9c.41.34,1.14.89,2.2,1.65l0,1.51q-4.63-2.79-5.72-3.57L24.89,34a6.46,6.46,0,0,0-.51.46.38.38,0,0,1-.23.14l-.94.77-2.29,1.5c-.67.39-1.17.68-1.51.86s-1,.57-1.52.93l0-1.11,2.89-2.71L22.52,33l.53-.36-.32-.38-.43-.59-.66-1A13,13,0,0,1,20,27.5a9.53,9.53,0,0,1-.17-6.39,9.54,9.54,0,0,1,1.56-2.79,6.3,6.3,0,0,1,1.08-1l.77-.81-.95-.74a11.94,11.94,0,0,0-2.12-1.62c-.74-.67-1.61-1.43-2.61-2.27l0-1.29,5.86,3.62,1.3.92,1.55-1.08Zm-5,14.05a11,11,0,0,0-.37-3.09,10.94,10.94,0,0,0-1.16-2.57l-.86-.94-.46.46a10.23,10.23,0,0,0-1.4,3.11,9.81,9.81,0,0,0-.13,1.27c0,.25,0,.79-.1,1.62a14.52,14.52,0,0,0,.11,2.92,13.26,13.26,0,0,0,1,2.74l.58.64a3.36,3.36,0,0,0,.34.37,2.52,2.52,0,0,0,.34.27l1-1,.48-1,.28-.95c.06-.4.13-.77.2-1.1A8.33,8.33,0,0,1,27.12,26,7.39,7.39,0,0,0,27.14,24.5Z"/>
          </svg>
      </transition>

  </div>
</template>

<script>
import {gsap} from "gsap";
export default {
    data() {
        return {
            elements: {
                innerCircleDark: null,
                innerCircleLight: null,
                outerCircle: null,
            },
        }
    },
    computed: {
        lightMode: function () {
            return this.$store.getters.mode;
        }
    },
    methods:{
        toggleLight(){



            let newMode;
            if(this.lightMode === 'light'){
                newMode = 'dark';
                gsap.timeline()
                    .to(this.elements.innerCircleDark, {width: '10px', height: '10px', autoAlpha: 0, zIndex: 2, duration: 0.1, stagger: 0.1})
                    .to(this.elements.innerCircleLight, {width: '24px', height: '24px', autoAlpha: 1, backgroundColor: 'var(--bg)', zIndex: 3,  duration: 0.666, stagger: 0.1}, '-=0.666')
                    .to(this.elements.innerCircleDark, {width: '100%', height: '100%', autoAlpha: 1, backgroundColor: 'var(--color)', zIndex: 2, duration: 0.666, stagger: 0.1})
            } else{
                newMode = 'light';
                gsap.timeline()
                    .to(this.elements.innerCircleLight, {width: '10px', height: '10px', autoAlpha: 0, zIndex: 2, duration: 0.1, stagger: 0.1})
                    .to(this.elements.innerCircleDark, {width: '23px', height: '23px', backgroundColor: 'var(--bg)', zIndex: 3,  duration: 0.666, stagger: 0.1}, '-=0.666')
                    .to(this.elements.innerCircleLight, {width: '100%', height: '100%', autoAlpha: 1, backgroundColor: 'var(--color)', zIndex: 2, duration: 0.666, stagger: 0.1}, '-=0.666')
                    // .to(this.elements.innerCircleLight, {width: '24px', height: '24px', backgroundColor: 'var(--bg)', zIndex: 2,  duration: 0.666, stagger: 0.1}, '-=0.666')
                    // .to(this.elements.innerCircleDark, {width: '100%', height: '100%', autoAlpha: 1, backgroundColor: 'var(--color)', zIndex: 1, duration: 0.666, stagger: 0.1})
            }
            this.$store.commit('setMode', newMode)
        },
        hoverOn(){
            if(this.lightMode === 'dark'){
                gsap.timeline()
                    .to(this.elements.innerCircleLight, {width: '27px', height: '27px', duration: 0.05, stagger: 0.1})
            }
            if(this.lightMode === 'light'){
                gsap.timeline()
                    .to(this.elements.innerCircleDark, {width: '27px', height: '27px', duration: 0.05, stagger: 0.1})
            }

        },
        hoverOut(){
            if(this.lightMode === 'dark'){
                gsap.timeline()
                    .to(this.elements.innerCircleLight, {width: '24px', height: '24px', duration: 0.05, stagger: 0.1})
            }
            if(this.lightMode === 'light'){
                gsap.timeline()
                    .to(this.elements.innerCircleDark, {width: '23px', height: '23px', duration: 0.05, stagger: 0.1})
            }

        },
    },
    mounted() {
        this.elements.innerCircleDark = document.querySelector('.light_switcher_circle_dark');
        this.elements.innerCircleLight = document.querySelector('.light_switcher_circle_light');
        this.elements.outerCircle = document.querySelectorAll('.light_switcher');

        gsap.timeline()
            .to(this.elements.outerCircle, {autoAlpha: 1, duration: 0.666, stagger: 0.1}, '+=1.222')
    }
}
</script>

<style scoped>
.light_switcher{
    visibility: hidden;
}

.cls-2{
    transition: ease all 0.333s;
}


@media (min-width:0px) and (max-width:1023px) {
    .darklighttext{
        display: none;
    }
    .light_switcher{
      width: 53px !important;
        height: 53px !important;
        bottom: 0px !important;
        top: initial !important;
    }

    .light_switcher svg path{
        fill: var(--color);
    }
}
@media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  {

}
@media (min-width:1024px) {
    .darklighttext{
        position: absolute;
        top: 12px;
        right: 100px;
        color: var(--color);
        font-size: 16pt;
        font-family: El Messiri, serif;
        transition: ease all 0.333s;
        opacity: 0;
        width: 150px !important;
        display: block;
    }

    .darklighttext span{

    }
    svg:hover .cls-2{
        opacity: 0.4;
    }
    .light_switcher:hover{
        opacity: 0.4;
    }

    .light_switcher:hover .darklighttext{
        opacity: 1 !important;
    }
    .light_switcher{
        display: block;
        position: absolute !important;
        right: 90px !important;
        top: 90px !important;
        height: 53px !important;
        width: 53px !important;
        cursor: pointer;
        //transition: 0.333s ease all;
    //padding: 1px;
    //border: 1px solid var(--color);
    }

    .light_switcher svg path{
        fill: var(--color);
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {

}
@media (min-width:1281px) {

}
</style>