<script>
import {gsap} from "gsap";
import { VEmojiPicker } from 'v-emoji-picker';

export default {
    name: "CommentsComponent",
    components:{
        VEmojiPicker
    },
    data() {
        return {
            isNewOpened: false,
            elements: {
              content: null,
                content_new: null,
                content_new_p: null,
                content_new_svg: null,
                content_new_close: null,
                form: null,
                comments: null
            },
            form:{
                name: null,
                text: '',
                subOf: null,
                isAdminComment: false,
                subOfString: null,
            },
            isCreated: false,
            emojiOpened: false,
            activeName: null,
            isSending: false,
            poem: [],
        }
    },
    computed: {
        fieldsFilled() {
            if(this.$route.path.includes('/admin')){
                return this.form.text;
            } else{
                return this.form.name && this.form.text;
            }

        },
        isAnyComments(){
            if(this.poem){
                if(this.poem.comments){
                    return this.poem.comments.length > 0;
                }

            }
            return false;

            // return this.poem?.comments?.length > 0;
        },
        route: function () {
            return this.$route.path;
        },
    },
    watch: {
        'route'(newValue) {
            if(!newValue.includes('/versek') && this.$store.getters.comments !== null){
                this.close();
            }
        },

    },
    methods:{
        selectEmoji(emoji) {
            this.form.text += emoji.data;
        },

        close(){
            this.$store.commit('setComments', null);
            gsap.timeline()
                .to(this.elements.comments, {autoAlpha: 0, x: 100, duration: 0.666, stagger: 0.1})
        },
        getDate (date) {
            let d = new Date(date);
            // let val;
            // let month;
            // if(d.getMonth() === '')

            let month = d.getMonth();

            if(month < 10){
                if(month + 1 < 10){
                    month = `0${month + 1}`
                } else{
                    month = month + 1;
                }

            }
            let day = d.getDate();
            if(day < 10) day = `0${day}`
            let hour = d.getHours();
            if(hour < 10) hour = `0${hour}`;
            let minutes = d.getMinutes();
            if(minutes < 10) minutes = `0${minutes}`;
            return `${d.getFullYear()}.${month}.${day}. ${hour}:${minutes}`;

        },
        reply(index){
            if(this.form.subOf === null){
                this.form.subOf = index.date;
                this.form.subOfString = `${index.name} - ${index.text}`
                if(!this.isNewOpened) this.openNew();
            } else{
                this.form.subOf = null;
            }

        },
        openNew(){
            this.isNewOpened = !this.isNewOpened;
            if(this.isNewOpened){
                gsap.timeline()
                    .to(this.elements.content, {height: 'calc(100% - 200px)', duration: 0.1, stagger: 0.1})
                    .to(this.elements.content_new, {height: '200px', duration: 0.1, stagger: 0.1}, '-=0.1')
                    .to(this.elements.content_new_p, {autoAlpha: 0, duration: 0.1, stagger: 0.1}, )
                    .to(this.elements.content_new_svg, {autoAlpha: 0, duration: 0.1, stagger: 0.1}, '-=0.1')
                    .to(this.elements.form, {autoAlpha: 1, duration: 0.1, stagger: 0.1}, )
                    .to(this.elements.content_new_close, {autoAlpha: 1, duration: 0.1, stagger: 0.1}, '-=0.1')
            } else{
                this.form.subOf = null;
                this.emojiOpened = false;
                gsap.timeline()
                    .to(this.elements.content, {height: 'calc(100% - 50px)', duration: 0.1, stagger: 0.1})
                    .to(this.elements.content_new, {height: '50px', duration: 0.1, stagger: 0.1}, '-=0.1')
                    .to(this.elements.form, {autoAlpha: 0, duration: 0.1, stagger: 0.1}, )
                    .to(this.elements.content_new_close, {autoAlpha: 0, duration: 0.1, stagger: 0.1}, '-=0.1')
                    .to(this.elements.content_new_p, {autoAlpha: 1, duration: 0.1, stagger: 0.1}, )
                    .to(this.elements.content_new_svg, {autoAlpha: 1, duration: 0.1, stagger: 0.1}, '-=0.1')

            }
        },
        deleteComment(comment, isSubComment = null){
            this.activeName = comment;
            if(isSubComment !== null) this.activeName.subOf = isSubComment.date;
            this.$bvModal.show('bv-modal-example')
        },
        async deleteCommentFromDb(){
            let id = this.$store.getters.comments._id;
            let result = await this.apiRequest('PUT', `/poem/comment/delete/${id}`, this.activeName);
            if (result) {
                await this.loadPoem();
                this.$bvModal.hide('bv-modal-example')

            }
        },
        async addComment(){
            if(this.$route.path.includes('/admin')){
                this.form.name = 'Darai Tamás';
                this.form.isAdminComment = true;
            }

            this.isSending = true;
            let result = await this.apiRequest('PUT', `/poem/comment/${this.poem._id}`, this.form);


            if(result?.status){
                this.isSending = false;
                this.isCreated = true;
                this.form.name = null;
                this.form.text = null;
                this.form.subOf = null;
                this.form.subOfString = null;
                this.form.isAdminComment = false;
                await this.loadPoem();
                this.emojiOpened = false;
                let timer = setTimeout(() => {
                    this.isCreated = false;

                    clearTimeout(timer);
                }, 3000);

            }
        },
        async loadPoem(){
            let result = await this.apiRequest('GET', `/poem/${this.poem.url}`);
            if(result?.data){
                this.$store.commit('setComments', result.data);
                this.poem = result.data;
            }
        },
        async handleClick(){
            if(this.fieldsFilled && !this.isCreated) await this.addComment();
        },
    },
    mounted() {
        document.addEventListener("commentOpened", () => {
            let value = this.$store.getters.comments;
            if(value?.comments){
                value.comments.sort(function(a, b) {
                    let c = new Date(a.date);
                    let d = new Date(b.date);
                    return c-d;
                }).reverse();

                value.comments.forEach(comment => {
                    if(comment.subComments){
                        comment.subComments.sort(function(a, b) {
                            let c = new Date(a.date);
                            let d = new Date(b.date);
                            return c-d;
                        });
                    }
                })
            }


            this.poem = value;

        })
        // this.poem = this.$store.getters.comments;
        // if(this.poem?.comments?.length > 0) this.isAnyComments = true;
        if(this.$route.path.includes('/admin')){
            this.form.name = 'Darai Tamás';
        }
        this.elements.comments = document.querySelector('.comments');
        this.elements.content = document.querySelector('.comments__block_content');
        this.elements.content_new = document.querySelector('.comments__block_new');
        this.elements.content_new_p = document.querySelector('.comments__new_p');
        this.elements.content_new_svg = document.querySelector('.comments__new');
        this.elements.content_new_close = document.querySelector('.comments__new_close');
        this.elements.form = document.querySelector('.comments__block_new_form');
    }
}
</script>

<template>
  <div class="comments">
      <svg class="comments__close" viewBox="7496 5161 16.453 13.358" @click="close">
          <path d="M 7496 5161 L 7504.3740234375 5174.3583984375 L 7512.453125 5161">
          </path>
      </svg>
      <div class="comments__block">
        <div class="comments__block_content">
            <p class="comments__block_content_empty" v-if="!isAnyComments">
                Nincsenek hozzászólások, legyél te az elsõ!
            </p>
            <div v-else>
                <div class="comments__block_content_comment" v-for="(comment, i) in poem.comments" v-bind:key="i">
                    <div class="comment" :class="{
                        admin_comment: comment.isAdminComment,
                        reply_opened: form.subOf !== null && form.subOf !== comment.date
                    }">
                        <div class="comments__block_content_comment_row">
                            <p class="comments__block_content_comment-name">
                                {{ comment.name }}
                            </p>
                            <p class="comments__block_content_comment-date">
                                {{ getDate(comment.date) }}
                            </p>
                            <p class="comments__block_content_comment-reply" @click="reply(comment)">

                                <span v-if="form.subOf === null">
                                    Válasz
                                </span>
                                <span v-else-if="form.subOf === comment.date">
                                    Mégse
                                </span>
                            </p>
                            <p v-if="$route.path.includes('/admin')" class="comments__block_content_comment-reply" @click="deleteComment(comment)">
                                <span >
                                    Törlés
                                </span>
                            </p>
                        </div>
                        <p class="comments__block_content_comment-text">
                            {{comment.text}}
                        </p>
                    </div>

<!--                    <div v-if="comment?.subComments?.length > 0">-->
                        <div class="sub" :class="{reply_opened: form.subOf !== null && form.subOf !== comment.date}" v-for="(subComment, j) in comment.subComments" v-bind:key="j">
                            <div class="sub__line">

                                <svg viewBox="0 0 5 31">
                                    <path d="M 0 0 L 0 31 L 5 31"/>
                                </svg>
                            </div>
                            <div class="sub__comment" :class="{admin_comment: subComment.isAdminComment}">
                                <div class="comments__block_content_comment_row">
                                    <p class="comments__block_content_comment-name">
                                        {{ subComment.name }}
                                    </p>
                                    <p class="comments__block_content_comment-date">
                                        {{ getDate(subComment.date) }}
                                    </p>
                                    <p class="comments__block_content_comment-reply" @click="reply(comment)">
                                        Válasz
                                    </p>
                                    <p v-if="$route.path.includes('/admin')" class="comments__block_content_comment-reply" @click="deleteComment(subComment, comment)">
                                <span >
                                    Törlés
                                </span>
                                    </p>
                                </div>
                                <p class="comments__block_content_comment-text">
                                   {{ subComment.text }}
                                </p>
                            </div>

                        </div>
<!--                    </div>-->

                </div>
            </div>

        </div>
          <div  class="comments__block_new">
            <p class="comments__new_p" @click="openNew">Hozzászólás írása</p>
              <svg class="comments__new" viewBox="7496 5161 16.453 13.358" @click="openNew">
                  <path class="comments__new_svg" d="M 7496 5161 L 7504.3740234375 5174.3583984375 L 7512.453125 5161">
                  </path>
              </svg>

              <div class="comments__block_new_form">
                  <b-form-input v-if="!$route.path.includes('/admin')" v-model="form.name" type="text" placeholder="Név"></b-form-input>
                  <b-form-textarea
                          v-model="form.text"
                          placeholder="Hozzászólás írása..."
                          rows="3"
                          max-rows="6"
                  ></b-form-textarea>
                  <p v-if="!isSending" class="comments__block_new_form_send" :class="{create_disabled: !this.fieldsFilled}" @click="handleClick">
                      <span v-if="isCreated">Hozzászólás elküldve!</span>
                      <span v-else>Küldés</span>
                  </p>
                  <p v-else class="comments__block_new_form_send create_disabled">
                      <span>Komment küldése folyamatban...</span>
                  </p>

                  <svg viewBox="0 0 369.946 369.946" class="comments__emoji" @click="emojiOpened = !emojiOpened">
                    <g>
                      <g>
                          <path class="emojifill" d="M184.973,0C82.975,0,0,82.975,0,184.973s82.975,184.973,184.973,184.973
                          s184.973-82.975,184.973-184.973S286.971,0,184.973,0z M248.622,111.378c14.261,0,25.854,11.6,25.854,25.86
                          s-11.594,25.854-25.854,25.854s-25.854-11.594-25.854-25.854S234.361,111.378,248.622,111.378z M121.33,111.378
                          c14.261,0,25.854,11.6,25.854,25.86s-11.594,25.854-25.854,25.854s-25.86-11.594-25.86-25.854S107.07,111.378,121.33,111.378z
                           M310.277,217.236h-1.11c-3.145,65.868-57.562,118.526-124.194,118.526S63.923,283.104,60.779,217.236h-1.11v-11.934h250.609
                          V217.236z"/>
                      </g>
                    </g>
                    </svg>
                  <svg class="comments__new_close" viewBox="7496 5161 16.453 13.358" @click="openNew">
                      <path class="comments__new_close_path" d="M 7496 5161 L 7504.3740234375 5174.3583984375 L 7512.453125 5161">
                      </path>
                  </svg>
                  <transition name="fade" mode="out-in">
                      <VEmojiPicker v-if="emojiOpened" @select="selectEmoji" />
                  </transition>

              </div>




          </div>
      </div>

      <b-modal id="bv-modal-example" hide-header centered>
          <template #modal-title>
              Komment törlése
          </template>
          <div class="d-block text-center">
              <p>
                  Biztosan törlöd a(z)
                  <br/>
                  <span v-if="activeName">{{ activeName.name }}</span>
                  <br/>
                  kommentjét?
              </p>
          </div>
          <template #modal-footer>
              <div class="w-100" style="text-align: center">
                  <b-button
                      @click="$bvModal.hide('bv-modal-example')"
                      size="sm"
                      class="float-right"
                  >
                      Nem
                  </b-button>
                  <b-button
                      style="margin-left: 10px;"
                      variant="danger"
                      size="sm"
                      class="float-right"
                      @click="deleteCommentFromDb()"
                  >
                      Igen
                  </b-button>
              </div>
          </template>
      </b-modal>
  </div>

</template>

<style scoped>
.sub__comment.admin_comment .comments__block_content_comment-text{
    padding-bottom: 0;
}
.sub__comment.admin_comment{
    margin-left: 0;
    width: 100%;
    padding-left: 15px;
    padding-top: 10px;
    margin-bottom: 20px;
    padding-bottom: 0;
}
.comments__block_new_form_send{
    transition: ease all 0.333s;
}
.comments__block_new_form_send:hover{
    color: var(--bg);
}
.comments__new_p{
    transition: ease all 0.333s;
}
.comments__block_new:hover .comments__new_p{
    color: var(--bg);
}

.comments__block_new:hover .comments__new path{
    stroke: var(--bg);
}

.comments__new_svg{
    transition: ease all 0.333s;
}
.emojifill{
    fill: var(--grey);
    transition: ease all 0.333s;
}

.comments__emoji{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 40px;
    bottom: 30px;
}

.comments__emoji:hover .emojifill{
    fill: var(--bg);
}
.emoji-picker{
    position: absolute;
    top: -240px;
    right: 500px;
}
.reply_opened{
    filter: blur(5px);
}
.create_disabled{
    cursor: default !important;
    color: var(--color_secondary) !important;
}
.hide_sub_line{
    opacity: 0;
}
.sub__line svg{
    width: 10px;
    margin-top: -16px;
    margin-left: 10px;
}

.sub__line svg path{
    fill: none;
    stroke: var(--admin_comment_grey);
    stroke-width: 2px;

}
.sub{
    display: flex;
}

.sub__comment{
    margin-left: 15px;
}
.admin_comment{
    background-color: var(--admin_comment_grey);
}

.comment{
    transition: 0.3s ease all;
}

.comments__block_content_comment-name{
    color: var(--bg);
    font-family: "El Messiri", serif;
    font-size: 16pt;
}

.comments__block_content_comment-date{
    color: var(--grey);
    font-family: "El Messiri", serif;
    font-size: 12pt;
    margin-left: 15px;
    margin-top: 4px;
}

.comments__block_content_comment-reply{
    color: var(--grey);
    font-family: "El Messiri", serif;
    font-size: 14pt;
    margin-left: 15px;
    margin-top: 2px;
    cursor: pointer;
    transition: 0.3s ease all;
}

.comments__block_content_comment-reply:hover{
    color: var(--bg);
}

.comments__block_content_comment-text{
    color: var(--bg);
    font-family: "El Messiri", serif;
    font-size: 14pt;
    line-height: 25px;
    padding-bottom: 10px;
}

.comments__block_content_comment_row{
    display: flex;
    margin-bottom: -15px;
}

.comments__block_content_comment{
    margin-bottom: 50px;
}

.comment{
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
}
.comments{
    position: fixed;
    right: 0px;
    transform: translateX(100px);
    top: 0;
    width: 500px;
    background-color: var(--color);
    height: 100vh;
    box-shadow: -45px -1px 163px 11px rgba(0,0,0,0.86);
    -webkit-box-shadow: -45px -1px 163px 11px rgba(0,0,0,0.86);
    -moz-box-shadow: -45px -1px 163px 11px rgba(0,0,0,0.86);
    padding: 50px 40px;
    visibility: hidden;
    z-index: 20;
}

.comments__block{
    position: relative;
    //background-color: #e74c3c;
    width: 100%;
    height: calc(100% - 70px);
    margin-top: 20px;

}

.comments__block_content{
    width: 100%;
    height: calc(100% - 50px);
    //background-color: #00ffaa;
    transition: 0.666s ease all;
    overflow-y: scroll;
    overflow-x: hidden;
}

.comments__block_new{
    width: 100%;
    height: 50px;
    //background-color: #096600;
    //display: flex;
    position: relative;
    padding: 10px;
}

.comments__block_new_form{
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
}

.comments__block_new_form .form-control{
    margin-bottom: 20px;
    max-height: 90px;
}

.comments__new_close:hover .comments__new_close_path{
    stroke: var(--bg);
}
.comments__close path, .comments__new path, .comments__new_close path {
    fill: transparent;
    stroke: var(--grey);
    transition: 0.333s ease all;
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.comments__close {
    overflow: visible;
    width: 16.453px;
    height: 13.358px;
    transform: rotate(270deg);
    cursor: pointer;
    transition: ease all 0.333s;
}

.comments__close:hover{
    opacity: 0.4;
}

.comments__new{
    overflow: visible;
    width: 16.453px;
    height: 13.358px;
    transform: rotate(180deg);
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 18px;
}

.comments__new_close {
    visibility: hidden;
    overflow: visible;
    width: 16.453px;
    height: 13.358px;
    transform: rotate(0deg);
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 34px;
}

.comments__block_new{
    color: var(--grey);
    font-size: 14pt;
    font-family: "El Messiri";
    cursor: pointer;
}
 .comments__block_content_empty{
    color: var(--bg);
    font-size: 14pt;
    font-family: El Messiri, serif;
    cursor: pointer;
}

@media (min-width: 0px) and (max-width: 1023px) {

    .emoji-picker{
        top: -110vw !important;
        left: 0;

    }
    .comments{
        position: fixed;
        right: 0px;
        transform: translateX(100px);
        top: 0;
        width: 100vw;
        background-color: var(--color);
        height: 100vh;
        box-shadow: -45px -1px 163px 11px rgba(0,0,0,0.86);
        -webkit-box-shadow: -45px -1px 163px 11px rgba(0,0,0,0.86);
        -moz-box-shadow: -45px -1px 163px 11px rgba(0,0,0,0.86);
        padding: 30px;
        visibility: hidden;
        z-index: 20;
    }

    .comments__block_content_comment_row{
        flex-flow: wrap;
    }

    .comments__block_content_comment_row p{
        width: 100%;
    }

    .comments__block_content_comment-name, .comments__block_content_comment-date{
        margin-bottom: 0 !important;
        margin-left: 0 !important;
    }

    .comments__block_content_comment-reply{
        margin-left: 10px;
        margin-bottom: 30px;
        white-space: pre;
    }

    .comments__block_content_comment-date{
        width: fit-content !important;
    }

    .comments__block_content_comment-reply{
        width: 40% !important;
    }
}
</style>