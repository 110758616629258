<script>
import {gsap} from "gsap";

export default {
    data() {
        return {
            timer: null,
        }
    },
    methods:{
        runTimeLine(){
            let arrows = document.querySelectorAll('.scrolldown svg')
            gsap.timeline({
                    repeat: 1000,
            })
                .to(arrows, {autoAlpha: 0, y: 42, duration: 0.666, stagger: 0.333})
                .to(arrows, {y: 0, duration: 0.666})
                .to(arrows, {autoAlpha: 1, duration: 0.666})

        }
    },
    mounted() {
        // this.timer = setTimeout(this.runTimeLine, 500)
        this.runTimeLine()
    },
    destroyed() {

    }
}
</script>

<template>
    <div class="scrolldown">
        <svg viewBox="7496 5161 16.453 13.358">
            <path d="M 7496 5161 L 7504.3740234375 5174.3583984375 L 7512.453125 5161">
            </path>
        </svg>
        <svg viewBox="7496 5161 16.453 13.358">
            <path d="M 7496 5161 L 7504.3740234375 5174.3583984375 L 7512.453125 5161">
            </path>
        </svg>
        <svg viewBox="7496 5161 16.453 13.358">
            <path d="M 7496 5161 L 7504.3740234375 5174.3583984375 L 7512.453125 5161">
            </path>
        </svg>
    </div>
</template>

<style scoped>
.scrolldown {
    position: relative;
    width: 16.453px;
    height: 42px;
}

svg path {
    fill: transparent;
    stroke: var(--grey);
    transition: 0.333s ease all;
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

svg {
    overflow: visible;
    position: absolute;
    width: 16.453px;
    height: 13.358px;
}
</style>