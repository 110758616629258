<template>
    <div id="admin-poem">
        <div @click="reverseBlocks" class="poem__date-filter">
            <svg class="poem__btn-filter" viewBox="0 0 26.13 22.43">
                <path class="cls-1"
                      d="M12.56,22.14.08.88A.58.58,0,0,1,.58,0h25a.58.58,0,0,1,.5.88L13.57,22.14A.58.58,0,0,1,12.56,22.14Z"/>
                <path class="cls-2"
                      d="M13.07,19.05,22.56,2.88a.58.58,0,0,0-.5-.88h-18a.59.59,0,0,0-.51.88l9.5,16.17m-.51,3.09L.08.88A.58.58,0,0,1,.58,0h25a.58.58,0,0,1,.5.88L13.57,22.14A.58.58,0,0,1,12.56,22.14Z"/>
            </svg>
            <!--    TODO: add logic here-->
            <p class="poem__filter">
                Szûrés idõrend szerint
            </p>
        </div>
        <div class="poem__list">


            <b-row v-for="(block, i) in blocks" v-bind:key="i">
                <b-col class="poem__list-date" cols="3">
                    <p>{{ block.year }}</p>
                </b-col>
                <b-col class="poem__list-titles">
                    <p
                            v-for="(poem, j) in block.poems"
                            v-bind:key="j"
                            @click="openRow(poem.url)"
                            :class="{active: poem.url === active}"
                    >
                        <span>{{ poem.title }}</span>
                        <!--&lt;!&ndash;            TODO: add edit poem view&ndash;&gt;-->
                        <!--&lt;!&ndash;            TODO: add delete poem dialog&ndash;&gt;-->
                        <span v-if="poem.url === active" class="poem__list-edit"
                              @click="changeRoute(`/admin/versek/szerkeszt/${poem.url}`)">szerkesztés</span>
                        <span v-if="poem.url === active" class="poem__list-delete"
                              @click="deletePoem(poem)">törlés</span>

                    </p>
                </b-col>
            </b-row>
        </div>

        <div class="admin__poem-new-block" @click="changeRoute(`/admin/versek/uj`)">
            <svg class="admin__poem-new-icon" viewBox="0 0 31.72 31.72">
                <path class="cls-2"
                      d="M31.71,15.79a14.46,14.46,0,0,0-.07-1.47c0-.39-.1-.79-.17-1.18,0-.11,0-.21,0-.32-.09-.47-.2-.93-.33-1.39l0-.09A14.1,14.1,0,0,0,30.58,10l0-.07c-.17-.43-.37-.85-.58-1.26a1.11,1.11,0,0,1-.07-.16c-.2-.37-.43-.74-.66-1.1,0-.08-.09-.17-.15-.25-.22-.34-.46-.66-.71-1-.08-.1-.15-.21-.23-.3-.32-.39-.66-.76-1-1.12a.46.46,0,0,0-.07-.07,15.85,15.85,0,1,0,0,22.42l.05-.05c.37-.37.71-.75,1-1.14l.22-.3c.25-.32.5-.64.72-1,.06-.08.1-.17.16-.25.23-.36.45-.73.65-1.1,0-.06.05-.11.07-.17.21-.41.41-.82.58-1.25a.26.26,0,0,0,0-.08,13.92,13.92,0,0,0,.48-1.37l0-.1c.13-.45.24-.92.33-1.38a1.88,1.88,0,0,0,0-.33,11.56,11.56,0,0,0,.17-1.18c.05-.48.07-1,.08-1.47C31.72,15.87,31.71,15.83,31.71,15.79Zm-6.05,9.87a13.86,13.86,0,1,1,4.06-9.8A13.88,13.88,0,0,1,25.66,25.66Z"/>
                <polygon class="cls-2"
                         points="16.59 5.15 14.59 5.15 14.59 14.82 4.92 14.82 4.92 16.82 14.59 16.82 14.59 26.49 16.59 26.49 16.59 16.82 26.26 16.82 26.26 14.82 16.59 14.82 16.59 5.15"/>
            </svg>
            <p class="admin__poem-new">Vers hozzáadása</p>
        </div>

        <b-modal id="bv-modal-example" hide-header centered>
            <template #modal-title>
                Vers törlése
            </template>
            <div class="d-block text-center">
                <p>
                    Biztosan törlöd a(z)
                    <br/>
                    <span v-if="activeName">{{ activeName.title }}</span>
                    <br/>
                    címû verset?
                </p>
            </div>
            <template #modal-footer>
                <div class="w-100" style="text-align: center">
                    <b-button
                            @click="$bvModal.hide('bv-modal-example')"
                            size="sm"
                            class="float-right"
                    >
                        Nem
                    </b-button>
                    <b-button
                            style="margin-left: 10px;"
                            variant="danger"
                            size="sm"
                            class="float-right"
                            @click="deleteP(activeName)"
                    >
                        Igen
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {gsap} from "gsap";

export default {
    name: "AdminPoemView",
    data() {
        return {
            active: null,
            blocks: [],
            isReversed: false,
            activeName: null,
        }
    },
    methods: {
        openRow(url) {
            if (this.active !== url) {
                this.active = url;
            } else {
                this.active = null;
            }

        },
        deletePoem(poem) {
            this.activeName = poem;
            this.$bvModal.show('bv-modal-example')
        },
        async deleteP(poem) {
            let result = await this.apiRequest('DELETE', `/poem/${poem._id}`);
            if (result) {
                await this.loadPoems();
                this.$bvModal.hide('bv-modal-example')

            }
        },
        async loadPoems() {
            let result = await this.apiRequest('GET', '/poem/all');
            if (result?.data) {
                this.blocks = result.data
            }
        },
        reverseArrays() {
            let filter = this.blocks;
            filter.forEach(e => {
                let poems = e.poems;
                e.poems = poems.reverse();
            })

            this.blocks = filter.reverse();
        },
        reverseBlocks() {

            this.isReversed = !this.isReversed;
            //
            let btn = document.querySelector('.poem__btn-filter');
            let poems = document.querySelectorAll('.poem__list .row');
            if (this.isReversed) {
                gsap.timeline()
                    .to(btn, {rotation: 0, duration: 0.333, stagger: 0.1})
                    .to(poems, {
                        autoAlpha: 0, duration: 0.666, x: '200px', stagger: 0.1, onComplete: () => {
                            this.reverseArrays();
                        }
                    }, '-=0.333')
                    .to(poems, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1},)
            } else {
                gsap.timeline()
                    .to(btn, {rotation: 180, duration: 0.666, stagger: 0.1})
                    .to(poems, {
                        autoAlpha: 0, duration: 0.666, x: '200px', stagger: 0.1, onComplete: () => {
                            this.reverseArrays();
                        }
                    }, '-=0.333')
                    .to(poems, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1},)

            }
        },
    },
    async mounted() {
        await this.loadPoems();
        let btn = document.querySelector('.poem__btn-filter');
        gsap.timeline()
            // .to(this.elements.poems, {autoAlpha: 0, duration: 0.005, x: '100px', stagger: 0.1})
            .to(btn, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1})

    }
}
</script>

<style scoped>
.d-block p {
    font-family: El Messiri;
    font-size: 25px;
}

.d-block p span {
    font-weight: bold;
}

#admin-poem .row:not(:last-child) {
    margin-bottom: 55px;
}

.poem__filter {

//position: absolute; font-family: El Messiri;
    font-size: 20px;
    color: white;
    margin-top: -5px;
    margin-left: 20px;

}

.poem__btn-filter {
//position: absolute; width: 27px;
    height: 23px;
    /*transform: matrix(1,0,0,1,412,128) rotate(180deg);*/
    rotate: 180deg;
    transform-origin: center;
    left: 412px;
    top: 100px;
    /*top: 10px;*/
}

.poem__btn-filter .cls-1 {
    fill: none;
}

.poem__btn-filter .cls-2 {
    fill: #575756;
}

.poem__date-filter {
    cursor: pointer;
    display: flex;
    left: 412px;
    top: 45px;
    position: absolute;
}

.poem__list-edit, .poem__list-delete, .poem__list-comments, .poem__list-delete_no {
    font-family: El Messiri;
    font-size: 15px;
    color: rgba(130, 48, 51, 1);
    letter-spacing: 0.2px;
    position: absolute;
    margin-top: 12px;
    cursor: pointer;
}

.poem__list-edit:hover, .poem__list-delete:hover {
    color: rgb(202, 48, 51);
}

.poem__list-delete {
    right: 85px;
    /*top: 11px;*/
}

.poem__list-delete_yes {
    right: 50px;
    /*top: 11px;*/
}

.poem__list-delete_no {
    right: 15px;
    /*top: 11px;*/
}

.poem__list-edit {

    right: 153px;
    /*top: 11px;*/
}

.poem__list {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 80vh;
    position: fixed;
    left: 405px;
    top: 120px;
    width: 900px;
}

.poem__list-date p {
    font-family: El Messiri, serif;
    font-size: 20px;
    color: var(--color_secondary);
    padding-top: 9px;
    padding-left: 10px;
}

.poem__list-titles p {
    font-family: El Messiri, serif;
    font-size: 28px;
    color: var(--color);
    margin-bottom: -3px;
    cursor: pointer;
}

.poem__list-titles {
    padding-left: 5px;
    position: relative;
}

.active {
    color: var(--color_secondary) !important;
}

.admin__poem-new-icon {
    width: 31.719px;
    height: 31.719px;

}

.admin__poem-new-icon .cls-2 {
    fill: #823033;
}

.admin__poem-new-block {
    cursor: pointer;
    right: 30px;
    top: 40px;
    position: absolute;
    display: flex;
}

.admin__poem-new {
//left: 1385px; //top: 73px; //position: absolute; overflow: visible;
    width: 210px;
    white-space: nowrap;
    line-height: 77px;

    margin-top: -21px;
    text-align: center;
    font-family: El Messiri;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
    letter-spacing: 2.41px;
}


</style>