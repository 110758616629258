<template>
    <div id="poem">
        <div @click="reverseBlocks" class="poem__date-filter">
            <svg class="poem__btn-filter" viewBox="0 0 26.13 22.43">
                <path class="cls-1"
                      d="M12.56,22.14.08.88A.58.58,0,0,1,.58,0h25a.58.58,0,0,1,.5.88L13.57,22.14A.58.58,0,0,1,12.56,22.14Z"/>
                <path class="cls-2"
                      d="M13.07,19.05,22.56,2.88a.58.58,0,0,0-.5-.88h-18a.59.59,0,0,0-.51.88l9.5,16.17m-.51,3.09L.08.88A.58.58,0,0,1,.58,0h25a.58.58,0,0,1,.5.88L13.57,22.14A.58.58,0,0,1,12.56,22.14Z"/>
            </svg>
            <!--    TODO: add logic here-->
            <p class="poem__filter">
                Szûrés idõrend szerint
            </p>
        </div>


        <div class="poem__list ">
            <!--            <transition-group name="slide" tag="b-row" mode="out-in">-->
                <b-row v-for="(block, i) in filtered" v-bind:key="i" >
                    <b-col class="poem__list-date poem__list-col" cols="3">
                        <p>{{ block.year }}</p>
                    </b-col>
                    <b-col class="poem__list-titles poem__list-col">
                        <!--                    <transition-group name="slide" tag="p" mode="out-in">-->
                        <p v-for="(poem, j) in block.poems" v-bind:key="j" @click="openPoem(poem.url)">
                            <span>{{ poem.title }}</span>

                        </p>
                        <!--                    </transition-group>-->
                    </b-col>
                </b-row>
            <!--            </transition-group>-->
        </div>

        <transition name="fade" mode="out-in">
            <div class="poem__scroll" :class="{scroll_disabled: !scrollEnabled}">
                <ScrollDown :key="keyValue"/>
            </div>
        </transition>


        <div class="menupoemmobile" v-touch:swipe="swipeDown">
            <MenuPoem />
        </div>

        <div class="menuopenmobile" @click="swipeUp">
            <p>Kategóriákhoz kattints!</p>
        </div>

    </div>
</template>

<script>
import ScrollDown from "@/components/ScrollDown.vue";
import {gsap} from "gsap";
import MenuPoem from "@/components/MenuPoem.vue";

export default {
    name: "PoemView",
    components:{
        ScrollDown,
        MenuPoem
    },
    data() {
        return {
            blocks: [],
            filtered: [],
            isReversed: false,
            elements: {
                filter: null,
                poems: null,
                dates: null,

            },
            isback: false,
            scrollEnabled: true,
            keyValue: 0
        }
    },
    computed: {
        route: function () {
            return this.$route.path;
        },
        // a computed getter
        // filteredBlocks: function () {
        //
        // }
        activeCategories: function () {
            let menu = this.$store.getters.menuPoem;
            let active = [];
            // let activeSubs = [];

            if (menu) {
                menu.forEach(m => {
                    if (m.active) {
                        active.push(m.name);
                        m.subCategories.forEach(sub => {
                            if (sub.active) {
                                active.push(sub.name);
                            }
                        })
                    }
                })
            }


            return {
                active: active,
                // activeSubs: activeSubs
            };
        },
    },
    watch: {
        activeCategories(newCount) {
            if (newCount.active.length === 0) {


                gsap.timeline()
                    .to(this.elements.poems, {
                        autoAlpha: 0, duration: 0.2, x: 0, stagger: 0.1, onComplete: () => {
                            this.filtered = this.blocks;
                        }
                    })
                    .to(this.elements.poems, {autoAlpha: 1, duration: 0.2, x: '0', stagger: 0.1})

            } else {


                let filtered = this.blocks;
                let newBlocks = [];

                filtered.forEach(block => {

                    let filteredPoems = [];

                    block.poems.forEach(poem => {


                        let actives = [];

                        poem.categories.forEach(category => {
                            if(category.active){
                                actives.push(category.name);
                                category.subCategories.forEach(subCategory => {
                                    if(subCategory.active){
                                        actives.push(subCategory.name);
                                    }
                                })
                            }
                        })

                        if(this.areAllElementsIncluded(actives, newCount.active)){
                            filteredPoems.push(poem);
                        }

                    })

                    if (filteredPoems.length > 0) {
                                newBlocks.push({
                                    year: block.year,
                                    poems: filteredPoems,
                                    empty: false,
                                })
                            }


                })


                    // this.filtered = newBlocks;

                    gsap.timeline()
                        // .to(this.elements.poems, {autoAlpha: 0, duration: 0.005, x: '100px', stagger: 0.1})
                        .to(this.elements.poems, {
                            autoAlpha: 0, duration: 0.2, x: 0, stagger: 0.1, onComplete: () => {
                                this.filtered = newBlocks;
                                if(this.filtered.length === 0){
                                    this.filtered = [{
                                        year: 'Nincs találat!',
                                        poems: [],
                                        empty: true
                                    }]
                                }
                            }
                        })
                        .to(this.elements.poems, {autoAlpha: 1, duration: 0.2, x: '0', stagger: 0.1})

            }
        }
    },
    methods: {
        swipeUp() {
            let menu = document.getElementById('menu__poem');
            let szellem = document.querySelector('.szellem');
            let lelek = document.querySelector('.lelek');
            let test = document.querySelector('.test');

            gsap.timeline()
                .to(menu, {autoAlpha: 1, duration: 0.333, zIndex: 1005, stagger: 0.1})
                .to(szellem, {autoAlpha: 1, duration: 0.333, stagger: 0.1}, '-=0.15')
                .to(lelek, {autoAlpha: 1, duration: 0.333, stagger: 0.1}, '-=0.15')
                .to(test, {autoAlpha: 1, duration: 0.333, stagger: 0.1, onComplete: () => {
                        // this.loadedActive.forEach(e => {
                        //     this.selectCategory(e)
                        // })
                    }}, '-=0.15');
        },
        swipeDown(){
            let menu = document.getElementById('menu__poem');

            gsap.timeline()
                .to(menu, {autoAlpha: 0, duration: 0.333, zIndex: 1, stagger: 0.1})
        },

        areAllElementsIncluded(array1, array2) {
            for (let i = 0; i < array2.length; i++) {
                if (!array1.includes(array2[i])) {
                    return false;
                }
            }
            return true;
        },
        compareSubArrays(array1, array2) {
            // Convert the arrays to Sets to efficiently check for element presence
            const set1 = new Set(array1);
            const set2 = new Set(array2);

            // Check if any element from array1 is in array2
            for (const element of set1) {
                if (set2.has(element)) {
                    return true;
                }
            }

            // Check if any element from array2 is in array1
            for (const element of set2) {
                if (set1.has(element)) {
                    return true;
                }
            }

            // If no common element is found, return false
            return false;
        },
        compareArrays(_arr1, _arr2) {
            if (
                !Array.isArray(_arr1)
                || !Array.isArray(_arr2)
                || _arr1.length !== _arr2.length
            ) {
                return false;
            }

            // .concat() to not mutate arguments
            const arr1 = _arr1.concat().sort();
            const arr2 = _arr2.concat().sort();

            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) {
                    return false;
                }
            }

            return true;
        },

        openPoem(route) {
            this.$store.commit('setTempMenuPoem', this.filtered);
            this.$router.push(`/versek/${route}`)
        },
        async loadPoems() {

            let temp = this.$store.getters.tempMenuPoem;
            if(temp.length > 0){
                this.blocks = temp;
                this.filtered = temp;
            } else{
                let result = await this.apiRequest('GET', '/poem/all');
                if (result?.data) {
                    this.blocks = result.data
                    this.filtered = result.data
                }
            }

        },
        reverseArrays() {
            let filter = this.filtered;
            filter.forEach(e => {
                let poems = e.poems;
                e.poems = poems.reverse();
            })

            this.filtered = filter.reverse();
            this.blocks = this.filtered;
        },
        reverseBlocks() {


            this.isReversed = !this.isReversed;
            //
            let btn = document.querySelector('.poem__btn-filter');
            if (this.isReversed) {
                gsap.timeline()
                    .to(btn, {rotation: 0, duration: 0.333, stagger: 0.1})
                    .to(this.elements.poems, {
                        autoAlpha: 0, duration: 0.666, x: '200px', stagger: 0.1, onComplete: () => {
                            this.reverseArrays();
                        }
                    }, '-=0.333')
                    .to(this.elements.poems, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1},)
            } else {
                gsap.timeline()
                    .to(btn, {rotation: 180, duration: 0.666, stagger: 0.1})
                    .to(this.elements.poems, {
                        autoAlpha: 0, duration: 0.666, x: '200px', stagger: 0.1, onComplete: () => {
                            this.reverseArrays();
                        }
                    }, '-=0.333')
                    .to(this.elements.poems, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1},)

            }
        },
    },
    async mounted() {
        await this.loadPoems();
        this.elements.filter = document.querySelector('.poem__date-filter');
        this.elements.poems = document.querySelectorAll('.poem__list .row');



            gsap.timeline()
                // .to(this.elements.poems, {autoAlpha: 0, duration: 0.005, x: '100px', stagger: 0.1})
                .to(this.elements.filter, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1})
                .to(this.elements.poems, {autoAlpha: 1, duration: 0.666, x: '0', stagger: 0.1}, '-=0.333')


        // this.elements.dates = document.querySelectorAll('.poem__list-date p');
        this.keyValue = 1;
        let list = document.querySelector('.poem__list')
        list.addEventListener('scroll', () =>
            {
                let scrollTop = list.scrollTop;
                let scrollHeight = list.scrollHeight;
                let offsetHeight = list.offsetHeight;
                let contentHeight = scrollHeight - offsetHeight;
                if (contentHeight <= scrollTop) {
                    this.scrollEnabled = false;
                }
                if(scrollTop === 0){
                    this.scrollEnabled = true;
                }
            },
            false
        );
    },
    // created() {
    //     this.$store.commit('setDefaultMenuPoem', true);
    // },
    destroyed() {
        this.keyValue = 0;
        gsap.timeline()
            // .to(this.elements.poems, {autoAlpha: 0, duration: 0.005, x: '100px', stagger: 0.1})
            .to(this.elements.filter, {autoAlpha: 0, duration: 0.666, x: '200px', stagger: 0.1})
            .to(this.elements.poems, {autoAlpha: 0, duration: 0.666, x: '200px', stagger: 0.1}, '-=0.333')
    }

}
</script>

<style scoped>

.scroll_disabled{
    opacity: 0;
}
.poem__list .row {
    visibility: hidden;
    transform: translateX(200px);
}

.poem__date-filter {
    cursor: pointer;
    visibility: hidden;
    transform: translateX(200px);

}

.poem__date-filter:hover .poem__filter, .poem__date-filter:hover .poem__btn-filter {
    opacity: 0.4 !important;
}



.poem__list-titles p:hover {
    opacity: 0.4;
}


@media (min-width: 0px) and (max-width: 360px){
    .poem__list{
        height: 50vh !important;
    }
}
@media (min-width: 0px) and (max-width: 1023px) {

    .poem__date-filter{
        visibility: initial;
        transform: translateX(200px);
        position: fixed;
        left: 0px;
        right: 0;
        top: 0px;
    }
.menuopenmobile{
    width: 100vw;
    height: 80px;
    padding: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 1000;
}

    .menuopenmobile p{
        //position: absolute;
        //left: 0;
        //right: 0;
        //margin: auto;
        //width: 100vw;
        font-family: El Messiri, serif;
        font-size: 1.2rem;
        color: var(--color);
        user-select: none;
    }
    .menupoemmobile{
        display: block;
        position: absolute;
        bottom: 0;
        height: fit-content;
    }
    .poem__list-titles {
        padding-left: 14px !important;
    }

    #poem .row:not(:last-child) {
        margin-bottom: 55px;
    }

    .poem__list {
        overflow-y: scroll;
        overflow-x: hidden;
        position: fixed;
        left: 0px;
        right: 0;
        top: 180px;
        margin: auto;
        width: 80vw;
        height: 57vh;

    }

    .poem__list-date p {
        font-family: El Messiri, serif;
        font-size: 20px;
        color: var(--grey);
        transition: 0.666s ease all;
        padding-top: 9px;
        padding-left: 0px;
    }

    .poem__list-titles p {
        font-family: El Messiri, serif;
        font-size: 28px;
        color: var(--color);
        transition: 0.333s ease all;
        margin-bottom: -3px;
        cursor: pointer;
    }

    .poem__list-titles p:last-child {
        margin-bottom: 0px !important;
    }

    .poem__list-titles {
        padding-left: 5px;
    }

    .poem__filter {
        left: 460px;
        top: 44px;
        position: absolute;

        font-family: El Messiri;
        font-size: 20px;
        color: var(--color);
        transition: 0.333s ease all;
    }

    .poem__btn-filter {
        position: absolute;

        width: 23px;
        height: 23px;
        /*transform: matrix(1,0,0,1,412,128) rotate(180deg);*/
        rotate: 180deg;
        transform-origin: center;
        left: 412px;
        top: 45px;
        transition: ease all 0.333s;
        /*top: 10px;*/
    }

    .poem__btn-filter .cls-1 {
        fill: none;
    }

    .poem__btn-filter .cls-2 {
        fill: var(--grey);
        transition: 0.666s ease all;
    }

    .poem__scroll{
        position: absolute;
        top: 100px;
        right: 34px;
    }
}

@media screen and (max-width: 1199px) {

    .poem__btn-filter {
        left: 40px;
        top: 130px;
    }

    .poem__filter {
        left: 85px;
        top: 129px;
    }

    .poem__list-date p {
        font-size: 12pt;
    }

    .poem__list-titles p {
        font-size: 16pt;
        margin-bottom: 0;
        margin-top: 3px;
    }

    .row {
        height: auto !important;
    }

    #poem .row:not(:last-child) {
        margin-bottom: 0px;
    }

    .poem__list-titles p:last-child {
        margin-bottom: 30px !important;
    }

    .poem__list-titles {
        padding-left: 21px;
    }

    .col-3 {
        width: 100%;
    }

    .col {
        margin-top: -13px;
    }
}

@media (min-width: 1024px) {
    .menuopenmobile{
        display: none;
    }
    .menupoemmobile{ display: none !important;}

    #poem .row:not(:last-child) {
        margin-bottom: 55px;
    }

    .poem__list {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 78vh;
        position: fixed;
        left: 405px;
        top: 120px;
        width: 900px;
    }

    .poem__list-date p {
        font-family: El Messiri, serif;
        font-size: 20px;
        color: var(--grey);
        transition: 0.666s ease all;
        padding-top: 9px;
        padding-left: 10px;
    }

    .poem__list-titles p {
        font-family: El Messiri, serif;
        font-size: 28px;
        color: var(--color);
        transition: 0.333s ease all;
        margin-bottom: -3px;
        cursor: pointer;
    }

    .poem__list-titles p:last-child {
        margin-bottom: 0px !important;
    }

    .poem__list-titles {
        padding-left: 5px;
    }

    .poem__filter {
        left: 460px;
        top: 44px;
        position: absolute;

        font-family: El Messiri;
        font-size: 20px;
        color: var(--color);
        transition: 0.333s ease all;
    }

    .poem__btn-filter {
        position: absolute;

        width: 27px;
        height: 23px;
        /*transform: matrix(1,0,0,1,412,128) rotate(180deg);*/
        rotate: 180deg;
        transform-origin: center;
        left: 412px;
        top: 45px;
        transition: ease all 0.333s;
        /*top: 10px;*/
    }

    .poem__btn-filter .cls-1 {
        fill: none;
    }

    .poem__btn-filter .cls-2 {
        fill: var(--grey);
        transition: 0.666s ease all;
    }

    .poem__scroll{
        position: absolute;
        bottom: 109px;
        right: 109px;
    }
}

@media (min-width: 1024px) and (max-width: 1365px) {
    .poem__list-date.poem__list-col.col-3{
        width: 10% !important;
    }

    .poem__btn-filter {
        left: 310px;
        /*top: 10px;*/
    }

    .poem__filter {
        left: 358px;
    }

    .poem__list {
        left: 310px;
        height: 65vh;
    }

    .poem__list-date p{
        padding-top: 0;
        padding-left: 0;
    }

    .col {
        margin-top: -10px;
    }
}

</style>